import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from '../Modal';
import { FloorForm } from '../forms/FloorForm';
import { deleteData, getData } from '../../utils/apiUtil';
import { RoomTypeForm } from '../forms/RoomTypeForm';
import { EditFloorForm } from '../forms/EditFloorForm';
import { EditRoomTypeForm } from '../forms/EditRoomTypeForm';
import DeleteForm from '../forms/DeleteForm';
import { ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/solid';
import { convertFloorRoomTypeMappingData, convertRoomTypeFloorMappingData } from '../viewLogic';
import { useApi } from '../ApiProvider';

export type Floor = {
    floorId: number;
    floorNumber: number;
    floorName: string;
};

export type RoomType = {
  roomTypeId: number;
  roomSharingType: string;
  amenities: string[];
  rentAmount: number;
  rentPerDayAmount: number;
  securityAmount: number
};


  
  export function FloorDetails() {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [floors, setFloors] = useState<Floor[]>([]);
    const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [editRoomType, setEditRoomType] = useState<RoomType|null>(null);
    const location = useLocation();
    const branchId = location.state.branchId;

    const [editRoomTypeFormProps, setEditRoomTypeFormProps] = useState<any>({});
    const [editFloorFormProps, setEditFloorFormProps] = useState<any>({});
    const [deleteFormProps, setDeleteFormProps] = useState<any>({});
    const [buttonClickType, setButtonClickType] = useState<string>("");
    const [notificationBannerProps, setNotificationBannerProps] = useState<any>({});
    const [selectedFloor, setSelectedFloor] = useState<Floor|undefined>(undefined);
    const [selectedRoomType, setSelectedRoomType] = useState<RoomType|undefined>(undefined);

    const [floorRoomTypeMappings, setFloorRoomTypeMappings] = useState({})
  const [roomTypeFloorMappings, setRoomTypeFloorMappings] = useState({})
    const api = useApi()
    // Floor methods

    const getFloors = () => {
      return getData(`${api.apiUrl}/floor/by-branch-id?branchId=${branchId}`)
      .then((data) => {
        setFloors(data);
      })  
    }

    useEffect(() => {
      getFloors();
    }, []);

    const handleAddFloorClick = () => {
      // handle add floor
      setButtonClickType("ADD_FLOOR");
      setShowModal(true);
    };

    const handleFloorEditClick = (floor) => {
      setEditFloorFormProps({
        floorName: floor.floorName,
        floorNumber: floor.floorNumber,
        floorId: floor.floorId,
        closeModal: () => {
          getFloors().then(() => {
            setShowModal(false)
            setSelectedFloor(undefined)
          })
        }
      })
      setButtonClickType("EDIT_FLOOR");
      setShowModal(true);
    };

    const handleFloorDeleteClick = (floor: Floor) => {
      setDeleteFormProps({
        showModal: true,
        title: "Delete Floor",
        message: `Are you sure you want to delete ${floor.floorName}?`,
        onDelete: (callback) => {
          deleteData(`${api.apiUrl}/floor/delete-by-id?floorId=${floor.floorId}`)
          .then((data) => {
            getFloors().then(() => {
              setShowModal(false)
              setSelectedFloor(undefined)
            })
          })
          .catch((error) => {
            console.log("Catch error : ", error)
            callback(error.toString());
          })
        },
        onClose: () => {
          setShowModal(false)
        }
      })
      setButtonClickType("DELETE");
      setShowModal(true)
    }

    // Room Type methods

    const getRoomTypes = () => {
      return getData(`${api.apiUrl}/room-type/by-branch-id?branchId=${branchId}`)
      .then((data) => {
        setRoomTypes(data);
      })  
    }

    useEffect(() => {
      getRoomTypes();
    }, []);

    const handleAddRoomTypeClick = () => {
      setButtonClickType("ADD_ROOM_TYPE");
      setShowModal(true);
    };

    const handleRoomTypeEditClick = (roomType) => {
      
      setEditRoomTypeFormProps({
        roomTypeId: roomType.roomTypeId,
        roomSharingType: roomType.roomSharingType,
        securityAmount: roomType.securityAmount,
        rentAmount: roomType.rentAmount,
        rentPerDayAmount: roomType.rentPerDayAmount,
        amenities: roomType.amenities,
        mappedFloors: roomTypeFloorMappings[roomType.roomTypeId] || [],
        floors: floors,
        closeModal: () => {
          getRoomTypes().then(() => {
            setShowModal(false)
            setSelectedRoomType(undefined)
          })
        }
      })
      setButtonClickType("EDIT_ROOM_TYPE");
      setShowModal(true);
    };

    const handleRoomTypeDeleteClick = (roomType) => {
      // TODO
      setDeleteFormProps({
        showModal: true,
        title: "Delete Room Type",
        message: `Are you sure you want to delete ${roomType.roomSharingType}?`,
        onDelete: (callback) => {
          deleteData(`${api.apiUrl}/room-type/by-id?roomTypeId=${roomType.roomTypeId}`)
          .then((data) => {
            getRoomTypes().then(() => {
              setShowModal(false)
              setSelectedRoomType(undefined)
            })
          })
          .catch((error) => {
            console.log("Catch error : ", error)
            callback(error);
          })
        },
        onClose: () => {
          setShowModal(false)
        }
      })
      setButtonClickType("DELETE");
      setShowModal(true)
    }

    // RoomType - Floor Mappings 

    const getRoomTypeFloorMappings = () => {
      return getData(`${api.apiUrl}/room-type/floorWiseMapping?branchId=${branchId}`)
      .then((data) => {
        const formattedFloorRoomTypeData = convertFloorRoomTypeMappingData(data)
        console.log("Formatted Floor RoomType Data : ", formattedFloorRoomTypeData)
        setFloorRoomTypeMappings(formattedFloorRoomTypeData);

        const formattedRoomTypeFloorData = convertRoomTypeFloorMappingData(data)
        console.log("Formatted RoomType Floor Data : ", formattedRoomTypeFloorData)
        setRoomTypeFloorMappings(formattedRoomTypeFloorData);


      })  
    }

    useEffect(()=>{
      getRoomTypeFloorMappings()
    },[roomTypes])

   

    return (
       <>
          {showModal &&  buttonClickType !== "DELETE" &&
            (
              <Modal showModal={showModal} onClose={() => setShowModal(false)} >
                  {buttonClickType == "ADD_FLOOR" && 
                  <FloorForm branchId={branchId} closeModal={() => {
                    getFloors().then(() => {
                      setShowModal(false)
                      setSelectedFloor(undefined)
                    })
                  }}/>}
                  {["EDIT_FLOOR"].includes(buttonClickType) && <EditFloorForm {...editFloorFormProps}/>}
                  {buttonClickType == "ADD_ROOM_TYPE" && 
                  <RoomTypeForm 
                    branchId={branchId} 
                    closeModal={() => {
                      getRoomTypes().then(() => {
                          setShowModal(false)
                          setSelectedRoomType(undefined)
                        })
                    }}
                    editMode={false}
                    editRoomType={null}
                    floors={floors}
                  />}
                  {["EDIT_ROOM_TYPE"].includes(buttonClickType)&& <EditRoomTypeForm {...editRoomTypeFormProps}/>}
              </Modal>
            )
          }
      {showModal && buttonClickType == "DELETE" && <DeleteForm {...deleteFormProps}/>}
      <div className='flex flex-row justify-end mb-5'>
        <button 
          className=" hover:bg-grey-200 text-blue-500 font-bold py-2 px-4 rounded"
          onClick={() => {
              navigate("/room-details", {
                state:{
                  branchId: branchId, 
                  floors: floors, 
                  roomTypes: roomTypes,
                  roomTypesByFloor: floorRoomTypeMappings
                }
              })
          }}
          
          >
            <div className='flex flex-row items-center  gap-x-2'>
              
              <p className=' flex items-center'>Room Details</p>
              <ArrowRightIcon className="w-4 h-4 text-blue-500 ml-1  "/> 
            </div>
          
        </button>
      </div>
      <div className="sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Floors</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={handleAddFloorClick}
            >
              + Add Floor
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Floor Number
                      </th> */}
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Floor Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                        <span className="sr-only">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white ">
                    {floors.map((floor) => (
                      <tr key={floor.floorId} className='flex flex-row justify-start'>
                        {/* <td 
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                        >
                          {floor.floorNumber}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{floor.floorName}</td>
                        
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button onClick={()=>handleFloorEditClick(floor)} className="text-indigo-600 hover:text-indigo-900">
                                Edit<span className="sr-only">, {floor.floorId}</span>
                            </button>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <button onClick={()=>handleFloorDeleteClick(floor)} className="text-red-600 hover:text-indigo-900">
                                Delete<span className="sr-only">, {floor.floorId}</span>
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-6 mt-20 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Room Types</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={handleAddRoomTypeClick}
            >
              + Add Room Type
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Room Sharing 
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Amenities
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Rent Amount
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Security Amount
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Floors
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {roomTypes.map((roomType) => (
                      <tr key={roomType.roomTypeId}>
                        <td 
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                        >
                          {roomType.roomSharingType}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r">
                                    <div className="flex flex-wrap">
                                        {roomType.amenities.map((amenity) => (
                                        <div key={amenity} className="border px-3 py-2 text-sm font-medium text-gray-500 mr-2 mb-2 ">{amenity}</div>
                                        ))}
                                        {roomType.amenities.length == 0 && 'No amenities'}
                                    </div>
                                </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roomType.rentAmount}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roomType.securityAmount}</td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roomTypeFloorMappings[roomType.roomTypeId].map(floorId => {
                          const filteredFloors = floors.filter(val => val.floorId == floorId)
                          console.log("FF : ", filteredFloors)
                          return filteredFloors.map(finalData => {
                            return (
                              <div key={finalData} className="border px-3 py-2 text-sm font-medium text-gray-500 mr-2 mb-2 ">{finalData.floorName}</div>
                            )
                          })
                        })}</td> */}
                        
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <button 
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick={()=>handleRoomTypeEditClick(roomType)}
                            >
                                Edit<span className="sr-only">, {roomType.roomTypeId}</span>
                            </button>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <button onClick={()=>handleRoomTypeDeleteClick(roomType)} className="text-red-600 hover:text-indigo-900">
                                Delete<span className="sr-only">, {roomType.roomTypeId}</span>
                            </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </> 
    )
  }
  