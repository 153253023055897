import { AnotherRoomEntity, FloorWiseRoomGroup, RoomGroup, RoomTypeFloorApiData, RoomTypeRooms } from "./types";

export function getRoomGroups(rooms: AnotherRoomEntity[]) {
    const formattedRooms: RoomGroup[] = Object.values(
        rooms.reduce((acc, room) => {
          const { roomId, roomNumber, roomTypeEntity, floorEntity } = room;
          const { roomTypeId,roomSharingType, amenitiesList, rentAmount, securityAmount } = roomTypeEntity;
          const { floorId, floorNumber, floorName } = floorEntity;
          const roomGroup = acc.find((group) => {
            return group.floorId === floorId && group.roomSharingType === roomSharingType && group.amenitiesList.join(',') === amenitiesList.join(',');
          });
          if (!roomGroup) {
            acc.push({
              floorId,
              floorNumber,
              floorName,
              roomSharingType,
              roomTypeId,
              amenitiesList,
              rentAmount,
              securityAmount,
              rooms: [{ roomId, roomNumber }],
            });
          } else {
            roomGroup.rooms.push({ roomId, roomNumber });
          }
          return acc;
        }, [] as RoomGroup[])
      );
      return formattedRooms;
}
  

export function getRoomGroupsByFloor(formattedRooms: RoomGroup[]): FloorWiseRoomGroup[] {
  const groupedRooms: FloorWiseRoomGroup[] = [];
  formattedRooms.forEach((room) => {
    const group = groupedRooms.find((group) => group.floorId === room.floorId);
    if (!group) {
      groupedRooms.push({ floorId: room.floorId, floorName: room.floorName, rooms: [room] });
    } else {
      group.rooms.push(room);
    }
  });
  groupedRooms.sort((a, b) => a.floorId - b.floorId);
  groupedRooms.forEach((group) => {
    group.rooms.sort((a, b) => a.roomTypeId - b.roomTypeId);
  });
  //console.log("groupedRooms for display : ", groupedRooms);
  return groupedRooms;
}

export function getUniqueFloorInfo(rooms: RoomGroup[]): { floorId: number; floorName: string }[] {
  const uniqueFloorInfo: { floorId: number; floorName: string }[] = [];

  rooms.reduce((accumulator, currentRoom) => {
    if (!accumulator.has(currentRoom.floorId)) {
      accumulator.add(currentRoom.floorId);
      uniqueFloorInfo.push({
        floorId: currentRoom.floorId,
        floorName: currentRoom.floorName
      });
    }
    return accumulator;
  }, new Set<number>());

  return uniqueFloorInfo;
}

export function getRoomInfoByFloor(rooms: RoomGroup[]): Record<number, { roomTypeId: number; roomSharingType: string }[]> {
  const roomInfoByFloor: Record<number, { roomTypeId: number; roomSharingType: string }[]> = {};

  rooms.forEach((roomGroup) => {
    const { floorId, roomTypeId, roomSharingType } = roomGroup;
    if (!roomInfoByFloor[floorId]) {
      roomInfoByFloor[floorId] = [];
    }
    roomInfoByFloor[floorId].push({ roomTypeId, roomSharingType });
  });

  return roomInfoByFloor;
}

export function convertFloorRoomTypeMappingData (data : RoomTypeFloorApiData) {

  const convertedData: {
    [floorId: number]: {
      roomTypeId: string;
      roomSharingType: string;
      amenities: string[];
    }[];
  } = {};

  data.roomTypeMappings.forEach((floorMapping) => {
    const { floorId, roomTypeDetails } = floorMapping;
  
    const convertedFloorData = roomTypeDetails.map((roomType) => ({
      roomTypeId: roomType.roomTypeId.toString(),
      roomSharingType: roomType.roomSharingType,
      amenities: roomType.amenitiesList,
    }));
  
    convertedData[floorId] = convertedFloorData;
  });

  return convertedData;
}

export function convertRoomTypeFloorMappingData (data : RoomTypeFloorApiData) {

  const convertedData: {
    [roomTypeId: number]: number[];
  } = {};
  
  data.roomTypeMappings.forEach((floorMapping) => {
    const { floorId, floorName, roomTypeDetails } = floorMapping;
  
    roomTypeDetails.forEach((roomType) => {
      const { roomTypeId } = roomType;
  
      if (!convertedData[roomTypeId]) {
        convertedData[roomTypeId] = [];
      }
  
      convertedData[roomTypeId].push(floorId);
    });
  });
  
  return convertedData;
}