import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from '../Modal';
import { getRoomGroups, getRoomGroupsByFloor, getRoomInfoByFloor, getUniqueFloorInfo } from '../viewLogic';
import { FloorWiseRoomGroup, Room, RoomGroup } from '../types';
import { Floor } from './FloorDetails';
import { RoomType } from './RoomTypeDetails';
import { RoomForm } from '../forms/RoomForm';
import { NewRoomForm } from '../forms/NewRoomForm';
import { deleteData, getData } from '../../utils/apiUtil';
import { PencilIcon, TrashIcon, PlusCircleIcon, ArrowRightIcon} from '@heroicons/react/solid';
import { EditRoomForm } from '../forms/EditRoomForm';
import { EditRoomTypeForm } from '../forms/EditRoomTypeForm';
import { EditFloorForm } from '../forms/EditFloorForm';
import DeleteForm from '../forms/DeleteForm';
import { AddRoomsForm } from '../forms/AddRoomsForm';
import NotificationBanner from '../NotificationBanner';
import { useApi } from '../ApiProvider';



export function RoomDetails() {

  const [showModal, setShowModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<RoomGroup>();
  const [floors, setFloors] = useState<Floor[]>([]);
  const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
  const [rooms, setRooms] = useState<RoomGroup[]>([]);
  const [buttonClickType, setButtonClickType] = useState<string>("");
  const [editRoomFormProps, setEditRoomFormProps] = useState<any>({});
  const [editRoomTypeFormProps, setEditRoomTypeFormProps] = useState<any>({});
  const [editFloorFormProps, setEditFloorFormProps] = useState<any>({});
  const [deleteFormProps, setDeleteFormProps] = useState<any>({});
  const [addRoomsFormProps, setAddRoomsFormProps] = useState<any>({});
  const [showNotificationBanner, setShowNotificationBanner] = useState<boolean>(false);
  const [notificationBannerProps, setNotificationBannerProps] = useState<any>({});
  const navigate = useNavigate();
  const location = useLocation();
  const api = useApi();

  const branchId = location.state.branchId;
  const branchFloors = location.state.floors;
  const branchRoomTypes = location.state.roomTypes;
  const roomTypesByFloor = location.state.roomTypesByFloor;

  console.log("Location state : ", location.state)
  const getRooms = async () => {
    const roomsData = await getData(`${api.apiUrl}/room-details/search?branchIds=${branchId}`)
    setRooms(getRoomGroups(roomsData));
  }

  const handleFloorEditClick = (floor) => {
    setEditFloorFormProps({
      floorName: floor.floorName,
      floorNumber: floor.floorNumber,
      floorId: floor.floorId,
      closeModal: () => {
        getRooms().then(() => {
          setShowModal(false)
          setSelectedRoom(undefined)
        })
      }
    })
    setButtonClickType("EDIT_FLOOR");
    setShowModal(true);
    };


  const handleRoomTypeEditClick = (room) => {

    setEditRoomTypeFormProps({
      roomTypeId: room.roomTypeId,
      roomSharingType: room.roomSharingType,
      securityAmount: room.securityAmount,
      rentAmount: room.rentAmount,
      amenities: room.amenitiesList,
      closeModal: () => {
        getRooms().then(() => {
          setShowModal(false)
          setSelectedRoom(undefined)
        })
      }
    })
    setButtonClickType("EDIT_ROOM_TYPE");
    setShowModal(true);
  };


  const handleRoomEditClick = (room: Room, floorId: number, roomTypeId: number) => {
    const uniqueFloors = getUniqueFloorInfo(rooms);
    const roomTypesFloorWise = getRoomInfoByFloor(rooms);
    console.log("Unique Floors : ", uniqueFloors)
    console.log("Room Types Floor Wise : ", roomTypesFloorWise)
    console.log("Rooms state : ", rooms)
    setEditRoomFormProps({
      room: room,
      floorId:floorId,
      roomTypeId: roomTypeId,
      floors: location.state.floors,
      roomTypes: location.state.roomTypes,
      roomTypesByFloor: location.state.roomTypesByFloor,
      onSuccess: (response: any) => {
        setShowNotificationBanner(true)
          setNotificationBannerProps({
            responseCode: response,
            responseMessage: response.message,
            onClose: () => {
              setShowNotificationBanner(false)
            }
          })
      },
      closeModal: () => {
        getRooms().then(() => {
          setShowModal(false)
          setSelectedRoom(undefined)
        })
      }
    })
    setButtonClickType("EDIT_ROOM");
    setShowModal(true);
  };


  const handleAddRoomTypeClick = (floorId: number, floorName: string) => {
      // create roomGroup with floorId and floorName
      const room: RoomGroup = {
        floorId: floorId,
        floorName: floorName,
        roomSharingType: "",
        amenitiesList: [],
        rentAmount: 0,
        securityAmount: 0,
        rooms: []
      }
      setButtonClickType("ROOM_TYPE");
      setSelectedRoom(room)
      setShowModal(true)
  }

  const handleAddFloorClick = () => {
    setButtonClickType("FLOOR");
    setShowModal(true)
  }

  const handleFloorDeleteClick = (floor: Floor) => {
    setDeleteFormProps({
      showModal: true,
      title: "Delete Floor",
      message: `Are you sure you want to delete ${floor.floorName}?`,
      onDelete: (callback) => {
        deleteData(`${api.apiUrl}/floor/delete-by-id?floorId=${floor.floorId}`)
        .then((data) => {
          getRooms().then(() => {
            setShowModal(false)
          })
        })
        .catch((error) => {
          console.log("Catch error : ", error)
          callback(error.toString());
        })
      },
      onClose: () => {
        setShowModal(false)
      }
    })
    setButtonClickType("DELETE");
    setShowModal(true)
  }




  const handleRoomDeleteClick = (room: Room) => {
    setDeleteFormProps({
      showModal: true,
      title: "Delete Room",
      message: `Are you sure you want to delete ${room.roomNumber}?`,
      onDelete: () => {
        deleteData(`${api.apiUrl}/room-details/delete?roomId=${room.roomId}`)
        .then((data) => {
          getRooms().then(() => {
            setShowModal(false)
            setSelectedRoom(undefined)
          })
        })
      },
      onClose: () => {
        setShowModal(false)
      }
    })
    setButtonClickType("DELETE");
    setShowModal(true)
  }

  const handleRoomAddClick = (floorId: number, roomTypeId: number) => {
    console.log("add room clicked....")
    setAddRoomsFormProps({
      floorId: floorId,
      roomTypeId: roomTypeId,
      floors: branchFloors,
      roomTypesByFloor: roomTypesByFloor,
      closeModal: () => {
        getRooms().then(() => {
          setShowModal(false)
          setSelectedRoom(undefined)
        })
      }
    })
    setButtonClickType("ADD_ROOMS");
    setShowModal(true);
  }



  useEffect(() => {
     getRooms();
  }, []);

  const formProps = {
    room: selectedRoom,
    floors: location.state.floors,
    roomTypes: location.state.roomTypes,
    roomTypesByFloor: location.state.roomTypesByFloor,
    closeModal: () => {
      getRooms().then(() => {
        setShowModal(false)
        setSelectedRoom(undefined)
      })
    },
    branchId: branchId,
    buttonClickType: buttonClickType
  }


  return (
      <>
      {showModal &&  buttonClickType !== "DELETE" &&
      (
          <Modal room={selectedRoom} showModal={showModal} onClose={() => setShowModal(false)} >
              {["FLOOR", "ROOM_TYPE"].includes(buttonClickType) && <NewRoomForm {...formProps}/>}
              {buttonClickType == "EDIT_FLOOR" && <EditFloorForm {...editFloorFormProps}/>}
              {buttonClickType == "EDIT_ROOM_TYPE" && <EditRoomTypeForm {...editRoomTypeFormProps}/>}
              {buttonClickType == "EDIT_ROOM" && <EditRoomForm {...editRoomFormProps}/>}
              {buttonClickType == "ADD_ROOMS" && <AddRoomsForm {...addRoomsFormProps}/>}
          </Modal>
      )}
      {showModal && buttonClickType == "DELETE" && <DeleteForm {...deleteFormProps}/>}
      {showNotificationBanner && <NotificationBanner {...notificationBannerProps}/>}
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto flex flex-row itens-center justify-between ">
          <h1 className="text-xl font-semibold  flex items-center text-gray-900">Room Details</h1>
          <button 
              className=" hover:bg-grey-200 text-blue-500 font-bold py-2 px-4 rounded"
              onClick={() => {
                  navigate("/floor-details", {state:{branchId: branchId}})
              }}
              >
              <div className='flex flex-row items-center  gap-x-2'>
              <p className=' flex items-center'>Floor and RoomType Details</p>
              <ArrowRightIcon className="w-4 h-4 text-blue-500 ml-1  "/> 
              
            </div>
          </button>
        </div>
        
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8 border-r"
                    >
                      Floor
                    </th>
                    
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                      Room Sharing
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                      Amenities
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                      Rent Amount
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                      Security Amount
                    </th>
                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                      Edit Room Type
                    </th> */}
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                      Room Number
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {getRoomGroupsByFloor(rooms).map((floorRoomGroup: FloorWiseRoomGroup, floorIndex) => (
                          <>
    
                              {floorRoomGroup.rooms.map((room, roomIndex) => 
                                (
                                  <tr key={room.roomTypeId}>
                                    <td className= {`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-600 sm:pl-6 lg:pl-8 border-r ${roomIndex==0 ? 'border-t-2 border-red-500':''}`} >
                                      <div className='flex items-center'>
                                        {/* {room.roomTypeId + " : " +floorRoomGroup.floorName} */}
                                        {roomIndex==0 ? floorRoomGroup.floorName : ""}
                                        {/* {roomIndex==0 && <PencilIcon  onClick={()=>handleFloorEditClick(floorRoomGroup)} className="w-4 h-4 text-blue-500 ml-1" />}
                                        { roomIndex==0 && <TrashIcon onClick={()=>handleFloorDeleteClick(floorRoomGroup)} className="w-4 h-4 ml-1" style={{ color: 'red' }}  />} */}
                                      </div>
                                    </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r">{room.roomSharingType}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r">
                                      <div className="flex flex-wrap">
                                          {room.amenitiesList.map((amenity, index) => (
                                          <div key={index} className="border px-3 py-2 text-sm font-medium text-gray-500 mr-2 mb-2 ">{amenity}</div>
                                          ))}
                                      </div>
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r">{room.rentAmount}</td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r">{room.securityAmount}</td>
                                  {/* <td className="whitespace-nowrap px-3 py-4 bg-white-500 hover:border hover:font-bold text-blue-700 text-sm border-r">
                                      <button onClick={()=>handleRoomTypeEditClick(room)}>Edit Room Type</button>
                                      </td> */}
                                  <td className="whitespace-nowrap px-3 py-4 text-sm border-r">
                                      <div className="flex flex-wrap">
                                          {room.rooms.map((roomi) => (
                                            <div className='flex justify-center'>
                                            <div key={roomi.roomId} className="border px-3 py-2 text-sm font-medium text-gray-500 mr-2 mb-2 flex items-center">
                                              {roomi.roomNumber + ""}
                                              <PencilIcon onClick={()=>handleRoomEditClick(roomi, floorRoomGroup.floorId, room.roomTypeId)} className="w-4 h-4 text-blue-500 ml-1" />
                                              <TrashIcon onClick={()=>handleRoomDeleteClick(roomi)} className="w-4 h-4 ml-1" style={{ color: 'red' }}  />
                                            </div>
                                            </div>
                                          ))}
                                          <div className='flex items-center'>
                                              <PlusCircleIcon onClick={()=>handleRoomAddClick(floorRoomGroup.floorId, room.roomTypeId)} className="w-4 h-4 mb-2 ml-1" style={{ color: 'green' }}  />
                                          </div>
                                      </div>
                                  </td>
                                  </tr>
                                )
                              )}
                          {/* <tr key={floorRoomGroup.floorId} style={{ textAlign: "center" }}>
                            <td colSpan={4}>
                            <button
          
                              className="bg-white-500 hover:border hover:font-bold text-blue-700 text-sm py-2  rounded mx-auto h-10 w-40"
                              onClick={() => {handleAddRoomTypeClick(floorRoomGroup.floorId, floorRoomGroup.floorName)}}
                            >
                             Add Room Type
                            </button>
                            </td>
                          </tr>    */}
                        </>
                  ))}
                </tbody>
              </table>
              <div className='flex justify-center items-center'>
                <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mx-auto"
                  onClick={() => {handleAddFloorClick()}}
                >
                  + Add Room
                </button> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
