import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from '../Modal';
import { RoomTypeForm } from '../forms/RoomTypeForm';
import { getData } from '../../utils/apiUtil';
import { useApi } from '../ApiProvider';


export type RoomType = {
    roomTypeId: number;
    roomSharingType: string;
    amenities: string[];
    rentAmount: number;
    securityAmount: number
  };
  
  export function RoomTypeDetails() {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [roomTypes, setRoomTypes] = useState<RoomType[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [editRoomType, setEditRoomType] = useState<RoomType|null>(null);
    const api = useApi();
    const location = useLocation();
    const branchId = location.state.branchId;

    useEffect(() => {
      getData(`${api.apiUrl}/room-type/by-branch-id?branchId=${branchId}`)
      .then((data) => {
        setRoomTypes(data);
      })
    }, []);

    const handleAddRoomTypeClick = () => {
      // handle add room type
      setShowModal(true);
    };
    const handleEditRoomTypeClick = (roomType: RoomType) => {
        // handle edit room type
        setShowModal(true);
        setEditMode(true);
        setEditRoomType(roomType);
    };
    return (
        <>
        {showModal && 
            (
                <Modal showModal={showModal} title={"RoomType"} onClose={() => setShowModal(false)} >
                    <RoomTypeForm editRoomType={editRoomType} editMode={editMode} closeModal={()=>{
                        setShowModal(false)
                        setEditMode(false)                        
                    }} branchId={branchId} />
                </Modal>
            )}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Room Types</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={handleAddRoomTypeClick}
            >
              + Add Room Type
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Room Sharing 
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Amenities
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Rent Amount
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-r">
                        Security Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {roomTypes.map((roomType) => (
                      <tr key={roomType.roomTypeId}>
                        <td 
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                        >
                          {roomType.roomSharingType}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-r">
                                    <div className="flex flex-wrap">
                                        {roomType.amenities.map((amenity) => (
                                        <div key={amenity} className="border px-3 py-2 text-sm font-medium text-gray-500 mr-2 mb-2 ">{amenity}</div>
                                        ))}
                                    </div>
                                </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roomType.rentAmount}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roomType.securityAmount}</td>
                        
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <a href="#" 
                            className="text-indigo-600 hover:text-indigo-900"
                            onClick = {() => handleEditRoomTypeClick(roomType)}
                            >
                                Edit<span className="sr-only">, {roomType.roomTypeId}</span>
                            </a>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <a href="#" className="text-red-600 hover:text-indigo-900">
                                Delete<span className="sr-only">, {roomType.roomTypeId}</span>
                            </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  