
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { getData, postData, putData } from "../../utils/apiUtil";
import { ExclamationIcon } from '@heroicons/react/outline'
import { useApi } from "../ApiProvider";
type EditFloorFormData = {
  floorName: string;
};

type EditFloorFormProps = {
    floorId: number;
    floorNumber: number;
    floorName: string;
    closeModal?: () => void;
}
const floorOptions = [
    { value: 'GROUND_FLOOR', label: 'GROUND_FLOOR' },
    { value: 'FIRST_FLOOR', label: ' FIRST_FLOOR' },
    { value: 'SECOND_FLOOR', label: 'SECOND_FLOOR' },
    { value: 'THIRD_FLOOR', label: 'THIRD_FLOOR' },
    { value: 'FOURTH_FLOOR', label: 'FOURTH_FLOOR' },
    { value: 'FIFTH_FLOOR', label: 'FIFTH_FLOOR' },
    { value: 'SIXTH_FLOOR', label: 'SIXTH_FLOOR' },
    { value: 'SEVENTH_FLOOR', label: 'SEVENTH_FLOOR' },
    { value: 'EIGHTH_FLOOR', label: 'EIGHTH_FLOOR' },
    { value: 'NINETH_FLOOR', label: 'NINETH_FLOOR' },
    { value: 'TENTH_FLOOR', label: 'TENTH_FLOOR' },
    { value: 'ELEVENTH_FLOOR', label: 'ELEVENTH_FLOOR' },
    { value: 'TWELVETH_FLOOR', label: 'TWELVETH_FLOOR' },
    { value: 'THIRTEENTH_FLOOR', label: 'THIRTEENTH_FLOOR' },
    { value: 'FOURTEENTH_FLOOR', label: 'FOURTEENTH_FLOOR' },
    { value: 'FIFTEENTH_FLOOR', label: 'FIFTEENTH_FLOOR' },
    { value: 'SIXTEENTH_FLOOR', label: 'SIXTEENTH_FLOOR' },
    { value: 'SEVENTEENTH_FLOOR', label: 'SEVENTEENTH_FLOOR' },
    { value: 'EIGHTEENTH_FLOOR', label: 'EIGHTEENTH_FLOOR' },
    { value: 'NINETEENTH_FLOOR', label: 'NINETEENTH_FLOOR' },
    { value: 'TWENTIETH_FLOOR', label: 'TWENTIETH_FLOOR' },
    { value: 'TWENTY_FIRST_FLOOR', label: 'TWENTY_FIRST_FLOOR' },
    { value: 'TWENTY_SECOND_FLOOR', label: 'TWENTY_SECOND_FLOOR' },
    { value: 'TWENTY_THIRD_FLOOR', label: 'TWENTY_THIRD_FLOOR' },
    { value: 'TWENTY_FOURTH_FLOOR', label: 'TWENTY_FOURTH_FLOOR' },
    { value: 'TWENTY_FIFTH_FLOOR', label: 'TWENTY_FIFTH_FLOOR' },
  ]

export function EditFloorForm(props: EditFloorFormProps) {

  const defaultValues = {
    floorNumber: props.floorNumber,
    floorName: props.floorName,
  }  
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<EditFloorFormData>({
    defaultValues
  });
  const [error, setError] = useState("");
  const api = useApi()

  const onEditFloorSubmit: SubmitHandler<EditFloorFormData> = async (data: EditFloorFormData) => {
    console.log("Floor form submitted : ", data);

    const requestData = {
        floorId: props.floorId,
        floorNumber: data.floorNumber,
        floorName: data.floorName,
    }

    putData(`${api.apiUrl}/floor/update-by-id`, requestData)
    .then((data) => {
        console.log("Edit Floor Response : ", data)
      props.closeModal && props.closeModal();
    })
    .catch(error => {
      setError(error)
    })

  };

  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onEditFloorSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Edit Floor 
        </h2>

        <div className="mt-10 flex flex-col  sm:flex-col ">
          
          <div className="flex-1 max-w-sm">
            <label
              htmlFor="owner-name"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Floor Name
            </label>
            <div className="mt-2">
            <Controller
            control={control}
            rules={{ required: true }}
            name={"floorName"}
            render={({ field }) => (
              <Select  
                options={floorOptions} 
                {...field}
                onChange = {(val) => field.onChange(val?.value)}
                value = { field.value ? floorOptions.find((c) => c.value === field.value) : null}
                />
            )}
          />
            </div>
          </div>

          { error && 
            <div className="rounded-md bg-red-50 p-2 mt-4">
              <div className="flex ">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                  </div>
                </div>
              </div>
            </div>}

        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{props.closeModal && props.closeModal()}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




