
import React, {useState} from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { patchData, postData } from "../../utils/apiUtil";
import { useApi } from "../ApiProvider";
import { ExclamationIcon } from '@heroicons/react/outline'
type OrgFormData = {
  // Define the type for your form data
  orgId?: number;
  brandName: string;
  ownerName: string;
  phoneNumber: string; 
  onboardingStatus: string;
  // ...
};

type OrgFormProps = {
  org: OrgFormData;
  closeModal: () => void;
}

const onboardingStatusOptions = [
  { value: 'REQUESTED', label: 'REQUESTED' },
  { value: 'IN_PROGRESS', label: 'IN_PROGRESS' },
  { value: 'COMPLETED', label: 'COMPLETED' },
]

export function OrgForm(props: OrgFormProps) {
  console.log(" Before Org form props : ", props);
  
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<OrgFormData>({
    defaultValues: props.org
  });
  const [error, setError] = useState("");
  const api = useApi()
  const onOrgSubmit: SubmitHandler<OrgFormData> = async (data: OrgFormData) => {
        

    if (props.org) {


      const mergePatchData = {
        brandName: data.brandName,
        ownerName: data.ownerName,
        phoneNumber: data.phoneNumber,
        onboardingStatus: data.onboardingStatus
      };

      console.log("merge patch data : ", mergePatchData);
      patchData(`${api.apiUrl}/org?id=${props.org.id}`, mergePatchData)
      .then((data) => {
        console.log("patched Org data : ", data);
        props.closeModal && props.closeModal();
      })
      .catch(error => {
        setError(error)
      })



    } else {
      const orgData = {
          brandName: data.brandName,
          ownerName: data.ownerName,
          phoneNumber: data.phoneNumber,
      };
      
      postData(`${api.apiUrl}/org`, orgData)
        .then((data) => {
          props.closeModal && props.closeModal();
        })
        .catch(error => {
          setError(error)
        })
    }
  };
  console.log(" data : ",watch())

  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onOrgSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Organization Information
        </h2>
       

        <div className="mt-10 flex flex-col  sm:flex-col ">
          <div className="flex-1 max-w-sm">
            <label
              htmlFor="brand-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Brand name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="brand-name"
                autoComplete="given-name"
                className="block w-full  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6 max-w-100"
                {...register("brandName")}
              />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="owner-name"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Owner name
            </label>
            <div className="mt-2">
              <input
                type="text"
                id="owner-name"
                autoComplete="given-name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 sm:text-sm "
                {...register("ownerName")}
              />
            </div>
          </div>
          <div className="flex-1 max-w-sm">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Phone number
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("phoneNumber")}
              />
            </div>
          </div>
          <div className="flex-1 max-w-sm mt-2">
              <label htmlFor="onboardingStatus" className=" mt-2 block text-sm font-medium text-gray-700">
                Onboarding status
              </label>
              <select
                {...register("onboardingStatus",{
                  required: "Onboarding status is required",
                })}
                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                {onboardingStatusOptions.map(option => <option value={option.value}>{option.label}</option>)}
              </select>
    
              { errors.branchAddress?.state && <p className="text-sm text-red-500">{errors.branchAddress?.state.message}</p>}
            </div>
          { error && <div className="rounded-md bg-red-50 p-2 mt-4">
                        <div className="flex ">
                          <div className="flex-shrink-0">
                            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                            <div className="mt-2 text-sm text-red-700">
                              <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                            </div>
                          </div>
                        </div>
                      </div>}
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{ props.closeModal && props.closeModal();}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




