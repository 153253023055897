
import React,{useState} from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { Floor } from "../views/FloorDetails";
import { RoomType } from "../views/RoomTypeDetails";
import { postData } from "../../utils/apiUtil";
import { RoomGroup } from "../types";
import { ExclamationIcon } from '@heroicons/react/outline'
import { useApi } from "../ApiProvider";


type AddRoomsFormData = {
    roomNumbers: string;
};

type AddRoomsFormProps = {
    floorId: number,
    roomTypeId: number,
    closeModal?: () => void;
}
  
export function AddRoomsForm(props: AddRoomsFormProps) {
  console.log("Add Room Form props : ", props)
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<AddRoomsFormData>();
  const [error, setError] = useState("");
  const api = useApi()
  
  const onAddRoomsSubmit: SubmitHandler<AddRoomsFormData> = async (data: AddRoomsFormData) => {
    console.log("Add Rooms form submitted : ", data);

    const requestData = {
        roomNumbers: data.roomNumbers.split(","),
        floorId: props.floorId,
        roomTypeId: props.roomTypeId,
    }
    console.log("Request Data : ", requestData)

    postData(`${api.apiUrl}/room-details/add`, requestData)
    .then((data) => {
        console.log("Add Rooms Response : ", data)
        props.closeModal && props.closeModal();
    })
    .catch(error => {
      setError(error)
    })
  };

  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onAddRoomsSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          New Rooms 
        </h2>

        <div className="mt-10 flex flex-col  sm:flex-col ">
          
            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                Room numbers
            </label>
            <div className="mt-1">
                <textarea
                {...register("roomNumbers")}
                rows={4}
                id="comment"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={''}
                />
            </div>
            { error && <div className="rounded-md bg-red-50 p-2 mt-4">
                        <div className="flex ">
                          <div className="flex-shrink-0">
                            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                            <div className="mt-2 text-sm text-red-700">
                              <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                            </div>
                          </div>
                        </div>
                      </div>}

        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{props.closeModal && props.closeModal()}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




