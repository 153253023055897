import React from "react";
import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../utils/apiUtil";
import { prodApiUrl, stagingApiUrl } from "../config";

const ApiContext = createContext({});

const ApiProvider = ({ children }) => {

    const [apiUrl, setApiUrl] = useState(stagingApiUrl);


    return (
        <ApiContext.Provider value={{ apiUrl, setApiUrl }}>
            {children}
        </ApiContext.Provider>
    );
};
export default ApiProvider;
export const useApi = () => {
    return useContext(ApiContext);
};