
import React,{useState} from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { Floor } from "../views/FloorDetails";
import { RoomType } from "../views/RoomTypeDetails";
import { postData } from "../../utils/apiUtil";
import { RoomGroup } from "../types";
import { useApi } from "../ApiProvider";
import { ExclamationIcon } from '@heroicons/react/outline'


type NewRoomFormData = {
  // Define the type for your form data
  
  floor: string;
  roomType?: number;
  roomSharingType?: string;
  amenities?: string[];
  rentAmount: number;
  securityAmount: number;
  roomNumbers: string[];
// ...
};

type NewRoomFormProps = {
    floors: Floor[];
    roomTypes: any[];
    roomTypesByFloor: number[];
    branchId: number;
    room: RoomGroup | undefined;
    buttonClickType: string;
    closeModal?: () => void;
}


let roomSharingTypeOptions : {label:string, value: string} | never[] = [];


  
export function NewRoomForm(props: NewRoomFormProps) {

    const floorOptions = props.floors.map(floor => ({
      label: floor.floorName,
      value: floor.floorId
    }));

    const room = Object.assign({}, props.room);
    console.log("New room form props : ",props)
    if(props.room) {
        console.log("Rooom in form : ", room)
    }
  
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<NewRoomFormData>({
    defaultValues: room,
  });
  const [error, setError] = useState("");
  const api = useApi()
  console.log("Watch : ", watch())
  const onRoomSubmit: SubmitHandler<NewRoomFormData> = async (data: NewRoomFormData) => {
    console.log("Room form submitted : ", data);
    // @ts-ignore
    data.roomNumbers = data.roomNumbers.split(",");
    //@ts-ignore
    data.branchId = props.branchId;
    const roomType = props.roomTypes.filter(roomType => roomType.roomTypeId == data.roomType)
    console.log("Room Type : ", roomType)
    data.roomSharingType = roomType[0].roomSharingType
    data.amenities = roomType[0].amenities
    console.log("Data : ", data)
    // print dirty fields in new lines
    console.log("Dirty fields : ", dirtyFields)

    const addRoomRequestBody = {
      floorId: data.floor,
      roomTypeId: data.roomType,
      roomNumbers: data.roomNumbers
    }
    postData(`${api.apiUrl}/room-details/add`, addRoomRequestBody)
      .then((addRoomResponseData) => {
          console.log("Room added : ", addRoomResponseData)
          props.closeModal && props.closeModal();
      })
      .catch((error) => {
          setError(error)
      })
  };
  console.log("Room Sharing Type Options : ", roomSharingTypeOptions)
  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onRoomSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          New Room Information
        </h2>

        <div className="mt-10 flex flex-col  sm:flex-col ">
            <div className="flex-1 max-w-sm">
                <label
                htmlFor="owner-name"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Floor
                </label>
                <div className="mt-2">
                <Controller
                    control={control}
                    name={"floor"}
                    render={({ field }) => (
                    
                    <Select 
                    options={floorOptions} 
                    {...field} 
                    onChange = {(val) => {
                      field.onChange(val.value)
                      roomSharingTypeOptions = props.roomTypesByFloor[val.value]?.map(roomType => {
                        return {
                          label: roomType.roomSharingType + ", " + roomType.amenities.length + " amenities",
                          value: roomType.roomTypeId
                        }
                      })

                    }}
                    value = {floorOptions.find((c) => c.value === field.value)}
                    isDisabled={props.buttonClickType === 'ROOM_TYPE'}/>
                    )}
                />
                </div>
            </div>

          <div className="mt-5 flex flex-col  sm:flex-col ">
            <div className="flex-1 max-w-sm">
                <label
                htmlFor="room-sharing-type"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Room Type
                </label>
                <div className="mt-2">
                <Controller
                    control={control}
                    name={"roomType"}
                    render={({ field }) => (
                    <Select 
                    options={roomSharingTypeOptions || []} 
                    {...field}
                    onChange = {(val) => {
                      field.onChange(val.value)
                      
                    }}
                    value = {roomSharingTypeOptions?.find((c) => c.value === field.value)}
                    />
                    )}
                />
                </div>
            </div>

            {/* <div className="flex-1 max-w-sm">
                <label
                htmlFor="owner-name"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Amenities
                </label>
                <div className="mt-2">
                    <Controller
                    control={control}
                    name={"amenities"}
                    render={({ field }) => (
                    <Select 
                     options={amenitiesOptions}
                     {...field}
                     onChange={(val) => field.onChange(val.map((c) => c.value))}
                     value = {amenitiesOptions.find((c) => c.value === field.value)}
                     />
                    )}/>
                </div>
            </div> */}
            {/* <div className="flex-1 max-w-sm">
                <label
                htmlFor="phone-number"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Rent Amount
                </label>
                <div className="mt-2">
                <input
                    type="tel"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("rentAmount")}
                />
                </div>
            </div> */}
            {/* <div className="flex-1 max-w-sm">
                <label
                htmlFor="phone-number"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Security Amount
                </label>
                <div className="mt-2">
                <input
                    type="tel"
                    id="phone-number"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("securityAmount")}
                />
                </div>
            </div> */}
        </div>

          <div className="mt-10 flex-1 max-w-sm">
            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                Room numbers
            </label>
            <div className="mt-1">
                <textarea
                {...register("roomNumbers")}
                rows={4}
                id="comment"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={''}
                />
            </div>
          </div>
          { error && 
            <div className="rounded-md bg-red-50 p-2 mt-4">
              <div className="flex ">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                  </div>
                </div>
              </div>
            </div>}

        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{props.closeModal && props.closeModal()}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




