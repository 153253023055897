/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../Modal';
import { BranchForm } from '../forms/BranchForm';
import { BranchEntity } from '../types';
import { SubscriptionForm } from '../forms/SubscriptionForm';
import PencilIcon from '@heroicons/react/solid/PencilIcon';
import Dropdown from '../Dropdown';
import { set } from 'lodash';
import SubscriptionHistory from './SubscriptionHistory';

export function BranchDetails(props) {
    const navigate = useNavigate();
    const [showBranchForm, setShowBranchForm] = useState(false);
    const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);
    const [isEditSubscription, setIsEditSubscription] = useState(false);
    const [showSubscriptionHistoryModal, setShowSubscriptionHistoryModal] = useState(false)
  console.log("props.selectedBranch", props.selectedBranch)
  console.log("showBranchForm", showBranchForm)
  console.log("Branch details branches : ", props.branches)

  const formatAddress = (addressDetails) => {
    let address = "";
    console.log("Address Details :", addressDetails)
    const addressLine1 = addressDetails.addressLine1 || "" ;
    const addressLine2 = addressDetails.addressLine2 || "" ;
    const landmark = addressDetails.landmark || "";
    const city = addressDetails.city  || "";
    const state = addressDetails.state || "";
    const country = addressDetails.country || "";
    const pincode = addressDetails.pincode || "";
    if (addressDetails) {
      address = [addressLine1, addressLine2, landmark, city, state, country, pincode]
        .filter((part) => part !== "")
        .join(", ");
    }
    return address;
  }
  const formatVisitorsEndTime = (visitorsEndTime: string) => {
    if(!visitorsEndTime) return "-"
    const dateTime = new Date(visitorsEndTime);
    return  dateTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }
  const address = formatAddress(props.selectedBranch.branchAddress);
  const visitorsEndTime = formatVisitorsEndTime(props.selectedBranch.visitorsPolicy.endTime);
  return (
    <>
        {showBranchForm && 
        (
            <Modal showModal={showBranchForm} onClose={() => {setShowBranchForm(false)}} >
               <BranchForm  
                isEdit={true}
                branch={props.selectedBranch}
                branches={props.branches}
                setBranches={props.setBranches}
                selectedBranch={props.selectedBranch}
                setSelectedBranch={props.setSelectedBranch}
                closeModal={()=>{setShowBranchForm(false)}}
                orgId={props.selectedBranch.orgId} />
            </Modal>
        )}
        {showSubscriptionForm &&
        (
            <Modal showModal={showSubscriptionForm} onClose={() => {setShowSubscriptionForm(false)}} >
               <SubscriptionForm  
                isEdit={isEditSubscription}
                selectedBranch={props.selectedBranch}
                setSelectedBranch={props.setSelectedBranch}
                subscription={props.selectedBranch.subscriptionDetails}
                closeModal={()=>{
                  setShowSubscriptionForm(false)
                  setIsEditSubscription(false)
                }}
                orgId={props.selectedBranch.orgId}
                branchId={props.selectedBranch.branchId} />
            </Modal>
        )}
        {showSubscriptionHistoryModal && 
          (
            <Modal showModal={showSubscriptionHistoryModal} onClose={() => {setShowSubscriptionHistoryModal(false)}} >
              <SubscriptionHistory branchId={props.selectedBranch.branchId}/>
            </Modal>
          
        )}

              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className='flex flex-row justify-end mb-8'>
                      <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => props.setSelectedBranch(null)}
                        >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="flex items-start justify-between">
                      
                      <div className='flex-col'>
                        <h2 className="text-xl font-bold">{props.selectedBranch.branchName}</h2> 
                        <p className='text-sm'>{address}</p>
                      </div>
                      <div className="ml-3 flex justify-between h-7 ">
                        <Dropdown options={[
                          {title: "Edit", onclick: () => {setShowBranchForm(true)}},
                          {title: "New Subscription", onclick: () => {
                            setShowSubscriptionForm(true)
                            setIsEditSubscription(false)
                          }},
                          {title: "Subcsription History", onclick: () => {
                            setShowSubscriptionHistoryModal(true);
                          }},
                        ]}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Branch Name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.branchName}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Branch Code</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.branchCode}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Branch Address</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{address!=="" ? address : '-'}</dd>
                    </div>

                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Latitude</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.branchAddress?.latitude}</dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Longitude</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.branchAddress?.longitude}</dd>
                </div>

                    {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Manager Name</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.managerDetails.managerName || '-'}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Manager Contact Number</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.managerDetails.managerContactNumber || '-'}</dd>
                    </div> */}

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Living Arrangement</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.livingArrangementType}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Stay Type</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.stayType}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Notice Period In Days</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {props.selectedBranch.agreementDetails?.noticePeriodInDays !== undefined 
                           ? props.selectedBranch.agreementDetails?.noticePeriodInDays :  "-"}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Lockin Period In Days</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {props.selectedBranch.agreementDetails?.lockinPeriodInDays !== undefined
                          ? props.selectedBranch.agreementDetails?.lockinPeriodInDays : "-"}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Services</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                            {props.selectedBranch.services.map((service) => (
                            <li key={service.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div className="w-0 flex-1 flex items-center">
                                <span className="ml-2 flex-1 w-0 truncate">{service}</span>
                                </div>
                            </li>
                            ))}
                        </ul>
                        </dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Is Meal Available</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch?.mealDetails?.isMealAvailable ? 'Yes' : 'No'}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Is Meal Optional</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch?.mealDetails?.isMealOptional ? 'Yes' : 'No'}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Meal Amount </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch?.mealDetails.mealAmount || '-'}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Rent Cycle Type</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.rentCycleType}</dd>
                    </div>


                    {/* <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Monthly Rent Collection Start Date</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.monthlyRentCollectionCycleDate}</dd>
                    </div> */}

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Allow Online Payments ?</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.allowOnlinePayments ? 'Yes' : 'No'}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Allow Complaints ?</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.allowComplaints ? 'Yes' : 'No'}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Total Beds Available</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.totalBeds}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Visitors Allowed</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.visitorsPolicy.areVisitorsAllowed ? 'Yes'  :'No'}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Visitors End Time</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{visitorsEndTime}</dd>
                    </div>

                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Electricity Payment Type</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.electricityPolicy?.paymentType }</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Electricity Rate per unit</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.electricityPolicy?.ratePerUnit || '-'}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Type</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails?.subscriptionType}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Start Date</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails?.subscriptionStartDate}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Status</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails?.subscriptionStatus}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Period</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails?.subscriptionPeriod + " months"}</dd>
                    </div>
                    
                    {  props.selectedBranch.subscriptionDetails?.subscriptionPaymentDetails &&
                    <>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Payment Status</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <div className='flex flex-row'>
                          {props.selectedBranch.subscriptionDetails.subscriptionPaymentDetails?.paymentStatus || '-'}
                          {props.selectedBranch.subscriptionDetails.subscriptionPaymentDetails?.paymentStatus === 'PENDING' &&
                          <PencilIcon  onClick={()=> {
                              setShowSubscriptionForm(true)
                              setIsEditSubscription(true)
                            }
                            } className="w-4 h-4 text-blue-500 ml-1" />}
                          </div>
                          </dd>
                       
                    </div>               
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Payment Amount</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails.subscriptionPaymentDetails?.paymentAmount || '-'}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Payment Mode</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails.subscriptionPaymentDetails?.paymentMode || '-'}</dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Subscription Payment Date</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.selectedBranch.subscriptionDetails.subscriptionPaymentDetails?.paymentDate || '-'}</dd>
                    </div>

                    </>
                    }
                  </dl>
                  <div className="mt-4 space-x-4 space-y-4">
                          <button className="hover:bg-grey-700 border-2 border-blue-500 text-blue-500 font-bold py-2 px-4 rounded">
                            Tenants
                          </button>
                          {/* <button 
                            className="hover:bg-grey-700 border-2 border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
                            onClick={() => {
                                navigate("/room-details", {state:{branchId: props.selectedBranch.branchId}})
                            }}
                            >
                            Room Details
                          </button> */}
                          <button 
                            className=" hover:bg-grey-700 border-2 border-blue-500 text-blue-500 font-bold py-2 px-4 rounded"
                            onClick={() => {
                                navigate("/floor-details", {state:{branchId: props.selectedBranch.branchId}})
                            }}
                            >
                            Floor and RoomType Details
                          </button>
                          {/* <button 
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => {
                                navigate("/room-type-details", {state:{branchId: props.selectedBranch.branchId}})
                            }}
                            >
                            Room Type Details
                          </button> */}
                        </div>
                </div>
                  </div>
                </div>
              </div>
      
    </>
  )
}
