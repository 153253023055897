
import React from "react";
import { useState } from 'react'
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { RoomType } from "../views/RoomTypeDetails";
import { postData } from "../../utils/apiUtil";
import { useApi } from "../ApiProvider";
import { InputOption } from "../InputOption";
import { Floor } from "../views/FloorDetails";
import { ExclamationIcon } from '@heroicons/react/outline'

type RoomTypeFormData = {
  // Define the type for your form data
  roomSharingType: string;
  amenities: string[];
  rentAmount: number; 
  rentPerDayAmount: number;
  securityAmount: number;
  branchId?: number;
  floors: string[]
// ...
};

type RoomTypeFormProps = {
    editMode: boolean;
    editRoomType: RoomType | null;
    branchId: number;
    floors: Floor[];
    closeModal?: () => void;
}

const roomTypesOptions = [
  { label: "Single Sharing", value: "SINGLE_SHARING" },
  { label: "Double Sharing", value: "DOUBLE_SHARING" },
  { label: "Triple Sharing", value: "TRIPLE_SHARING" },
  { label: "Four Sharing", value: "FOUR_SHARING" },
  { label: "Five Sharing", value: "FIVE_SHARING" },
];


const options = [
  { label: "Television", value: "TELEVISION" },
  { label: "Table", value: "TABLE" },
  { label: "Chair", value: "CHAIR" },
  { label: "Air Conditioning", value: "AIR_CONDITIONING" },
  { label: "Cooler", value: "COOLER" },
  { label: "Fan", value: "FAN" },
  { label: "Almirah / Cupboard", value: "CUPBOARD" },
  { label: "Bed with Mattress", value: "BED_MATTRESS" },
  { label: "Geyser", value: "GEYSER" },
  { label: "Balcony", value: "BALCONY" },
  { label: "Bedsheets & Pillow", value: "BEDSHEETS_PILLOW" },
  { label: "Blanket", value: "BLANKET" },
  { label: "Bucket & Mug", value: "BUCKET_MUG" },
  { label: "Kitchen Area", value: "KITCHEN_AREA" },
  { label: "Induction Cooktop", value: "INDUCTION_COOKTOP" },
  { label: "Bookshelf", value: "BOOKSHELF" },
  { label: "Refrigerator / Fridge", value: "REFRIGERATOR" },
  { label: "Microwave", value: "MICROWAVE" },
  { label: "Mosquitoes Repellent", value: "MOSQUITO_REPELLENT" },
];


export function RoomTypeForm(props: RoomTypeFormProps) {

  const defaultAmenities = props.editRoomType?.amenities.map((amenity) => {
    return {
      value: amenity,
      label: amenity,
    };
  })

  const floorOptions = props.floors.map(floor => ({
    label: floor.floorName,
    value: floor.floorId
  }));

  const defaultRoomTypeValues = {
    roomSharingType: props.editRoomType?.roomSharingType || "",
    amenities: defaultAmenities || [],
    rentAmount: props.editRoomType?.rentAmount ,
    securityAmount: props.editRoomType?.securityAmount ,
    branchId: props.branchId,
    
  };  
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<RoomTypeFormData>({
    defaultValues: defaultRoomTypeValues
  });
  const [error, setError] = useState("");
  // const displayError = (error: string) => {
  //   console.log("display error : ", error);
  //   setError(error);
  // }
  console.log(props)
  const navigate = useNavigate();
  const api = useApi();
  
  const onRoomTypeSubmit: SubmitHandler<RoomTypeFormData> = async (data: RoomTypeFormData) => {
    console.log("Room Type form submitted : ", data);
    data.amenities = data.amenities || []
    data.branchId = props.branchId;
    postData(`${api.apiUrl}/room-type/add`, data)
      .then((responseData) => {
        console.log("POST room type response data : ", responseData)
        const mappingRequestData = {
          roomTypeId: responseData.roomTypeId,
          floorIds: data.floors
        }
        console.log("Mapping request data : ", mappingRequestData)
        postData(`${api.apiUrl}/room-type/mapping`, mappingRequestData)
        .then((mappingResponseData) => {
          props.closeModal && props.closeModal();
        })
        .catch(error => {
          console.log("display error : ", error);
          setError(error)
        })
      })
      .catch(error => {
        console.log("display error : ", error);
        setError(error)
      })
    
  };

  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onRoomTypeSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Room Type Information
        </h2>

        <div className="mt-10 flex flex-col  sm:flex-col ">

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="roomTypeId"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Room Sharing Type
            </label>
            <div className="mt-2">
            <Controller
                control={control}
                rules={{ required: true }}
                name={"roomSharingType"}
                render={({ field }) => {
                    return (
                        <Select  
                          options={roomTypesOptions} 
                          {...field}
                          onChange = {(val) => field.onChange(val?.value)}
                          value = { field.value ? roomTypesOptions.find((c) => c.value === field.value) : null}
                          defaultValue={null} 
                          
                        />
                    )
                }}
            />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="owner-name"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Amenities
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                name={"amenities"}
                render={({ field }) => (
                  <Select 
                    isMulti 
                    {...field}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => {
                      if (Array.isArray(options)) {
                        field.onChange(options.map((opt) => opt.value));
                      }
                    }}
                    value={field.value ? options.filter((c) => field.value.includes(c.value)) : null}
                    options={options}
                    components={{
                      Option: InputOption
                    }}
                  />
              )}/>
            </div>
          </div>
          <div className="flex-1 max-w-sm">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Rent Amount
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("rentAmount")}
              />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Rent Amount Per Day
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("rentPerDayAmount")}
              />
            </div>
          </div>



          <div className="flex-1 max-w-sm">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Security Amount
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("securityAmount")}
              />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="owner-name"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Floors
            </label>
            <div className="mt-2">
              <Controller
                control={control}
                rules={{ required: true }}
                name={"floors"}
                render={({ field }) => (
                  <Select 
                    isMulti 
                    {...field}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => {
                      if (Array.isArray(options)) {
                        field.onChange(options.map((opt) => opt.value));
                      }
                    }}
                    value={field.value ? floorOptions.filter((c) => field.value.includes(c.value)) : null}
                    options={floorOptions}
                    components={{
                      Option: InputOption
                    }}
                  />
              )}/>
            </div>
          </div>

          { error && 
            <div className="rounded-md bg-red-50 p-2 mt-4">
              <div className="flex ">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                  </div>
                </div>
              </div>
            </div>}

          
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{props.closeModal && props.closeModal()}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




