// api.js

const bearerToken = 'Bearer ' + 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyMDIiLCJmaXJzdE5hbWUiOiJBc2hpc2giLCJvdHBsZXNzVG9rZW4iOiJhYmNkMTIzNCIsImV4cCI6MTkxNTE4NTEwMH0.tX8Ba3Zu4HJM7ivpkKTMPwvwwcgSQrVosQHY6KQt9YU';

// Function to make a POST request to the API
export const postData = async (url: string, data) => {
 
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(`${responseData.error?.message || responseData.detail}`);
    }
    return responseData;
  
};

export const postZaakpayData = async (url: string, data) => {
 
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  console.log("Response : ", response)
  const responseData = await response.text();
  if (!response.ok) {
    throw new Error(`${response.error?.message || response.detail}`);
  }
  return responseData;

};


// Function to make a PUT request to the API
export const putData = async (url: string, data) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(`${responseData.error?.message || responseData.detail}`);
    }
    return responseData;
  } catch (error) {
    throw error;
  }
};


// Function to make a GET request to the API
export const getData = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: bearerToken,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(`${responseData.error?.message || responseData.detail}`);
    }
    return responseData;
  } catch (error) {
    throw new Error(`${error.message}`);
  }
};

export const patchData = async (url: string, data: any) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: bearerToken,
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(`${responseData.error?.message || responseData.detail}`);
    }
    return responseData;
  } catch (error) {
    throw new Error(`${error.message}`);
  }
}

export const deleteData = async (url: string) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(`${responseData.error?.message || responseData.detail}`);
    }
  } catch (error) {
    throw new Error(`${error.message}`);
  }
} 