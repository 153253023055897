import React, { useEffect } from "react";
import { OrgForm } from "./components/forms/OrgForm";
import { BranchForm } from "./components/forms/BranchForm";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SideBar from "./components/Sidebar";
import { Dashboard } from "./components/Dashboard";
import { OrgDetails } from "./components/views/OrgDetails";
import { RoomDetails } from "./components/views/RoomDetails";
import { FloorDetails } from "./components/views/FloorDetails";
import { RoomTypeDetails } from "./components/views/RoomTypeDetails";
import Breadcrumbs from "./components/Breadcrumbs";
import {requestForToken, onMessageListener} from "./firebase"
import AuthProvider from "./components/AuthProvider";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import ApiProvider from "./components/ApiProvider";
const routes = [
  {path: "/branch-form", name: "Branch Form", Component: BranchForm},
  {path: "/", name: "Dashboard", Component: Dashboard},
  {path: "/org-details", name: "Org/Branch Details", Component: OrgDetails},
  {path: "/room-details", name: "Room Details", Component: RoomDetails},
  {path: "/floor-details", name: "Floor and RoomType Details", Component: FloorDetails},
]

export function App() {

  useEffect(()=> {
    requestForToken()
  }, [])

  
  onMessageListener().then(payload => {
    console.log(payload);
  }).catch(err => console.log('failed: ', err));


  return (
    <>
      <Router>
        <ApiProvider>
          <AuthProvider>
            <SideBar>
              {/* <Breadcrumbs/> */}
              <Routes>
                {/* {routes.map(({ path, Component }, key) => (
                  <Route 
                  path={path} 
                  key={key} 
                  element={<Component/>} />
              ))} */}
                <Route path="login" element={<Login />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/branch-form" element={<BranchForm />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/org-details" element={<OrgDetails />} />
                  <Route path="/room-details" element={<RoomDetails />} />
                  <Route path="/floor-details" element={<FloorDetails />} />
                  <Route path="/room-type-details" element={<RoomTypeDetails />} />
                </Route>
              </Routes>
            </SideBar>
          </AuthProvider>
        </ApiProvider>
      </Router>
    </>
  );
}

