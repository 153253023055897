
import React, { useState, useEffect } from 'react'
import SideBar from "../Sidebar";
import {useForm, SubmitHandler, Controller} from 'react-hook-form'
import TimePicker from 'react-time-picker';
import { patchData, postData } from '../../utils/apiUtil';
import _, { endsWith, set } from 'lodash';
import Select from "react-select";
import { Datepicker } from 'flowbite-react';
import { InputOption } from '../InputOption';
import { removeEmptyFields } from '../../utils/helpers';
import { ExclamationIcon } from '@heroicons/react/outline'
import { useApi } from '../ApiProvider';
type BranchFormProps = {
  isEdit: boolean;
  branch: any;
  branches: any[];
  selectedBranch: any;
  setSelectedBranch?: (branch: any) => void;
  setBranches?: (branches: any[]) => void;
  closeModal: () => void;
  orgId: number;
}
const servicesOptions = [
  { value: "WIFI", label: "WiFi" },
  { value: "HOUSEKEEPING", label: "HouseKeeping" },
  { value: "GYM", label: "Gym" },
  { value: "LAUNDRY", label: "Laundry" },
  { label: "Pest Control", value: "PEST_CONTROL" },
  { label: "Washing Machine", value: "WASHING_MACHINE" },
  { label: "2-Wheeler Parking", value: "TWO_WHEELER_PARKING" },
  { label: "4-Wheeler Parking", value: "FOUR_WHEELER_PARKING" },
  { label: "Swimming Pool", value: "SWIMMING_POOL"},
  { label: "Pool / Snooker Table", value: "SNOOKER_TABLE"},
  { label: "Foosball Table", value: "FOOSBALL_TABLE"},
  { label: "Table Tennis / Ping Pong Table", value: "PING_PONG_TABLE"},
  { label: "Carrom Board", value: "CARROM_BOARD"},
  { label: "CCTV", value: "CCTV"},
  { label: "24*7 Security", value: "TWENTY_FOUR_SEVEN_SECURITY"},
  { label: "Water Cooler", value: "WATER_COOLER"},
  { label: "Coffee Machine", value: "COFFEE_MACHINE"},
  { label: "Common Refrigerator / Fridge", value: "COMMON_REFGRIGERATOR"},
  { label: "Common Microwave", value: "COMMON_MICROWAVE"},
  { label: "Vending Machine", value: "VENDING_MACHINE"},
];
const stateOptions = [
  { value: 'ANDHRA_PRADESH', label: 'Andhra Pradesh' },
  { value: 'ARUNACHAL_PRADESH', label: 'Arunachal Pradesh' },
  { value: 'ASSAM', label: 'Assam' },
  { value: 'BIHAR', label: 'Bihar' },
  { value: 'CHHATTISGARH', label: 'Chhattisgarh' },
  { value: 'GOA', label: 'Goa' },
  { value: 'GUJARAT', label: 'Gujarat' },
  { value: 'HARYANA', label: 'Haryana' },
  { value: 'HIMACHAL_PRADESH', label: 'Himachal Pradesh' },
  { value: 'JHARKHAND', label: 'Jharkhand' },
  { value: 'KARNATAKA', label: 'Karnataka' },
  { value: 'KERALA', label: 'Kerala' },
  { value: 'MADHYA_PRADESH', label: 'Madhya Pradesh' },
  { value: 'MAHARASHTRA', label: 'Maharashtra' },
  { value: 'MANIPUR', label: 'Manipur' },
  { value: 'MEGHALAYA', label: 'Meghalaya' },
  { value: 'MIZORAM', label: 'Mizoram' },
  { value: 'NAGALAND', label: 'Nagaland' },
  { value: 'ODISHA', label: 'Odisha' },
  { value: 'PUNJAB', label: 'Punjab' },
  { value: 'RAJASTHAN', label: 'Rajasthan' },
  { value: 'SIKKIM', label: 'Sikkim' },
  { value: 'TAMIL_NADU', label: 'Tamil Nadu' },
  { value: 'TELANGANA', label: 'Telangana' },
  { value: 'TRIPURA', label: 'Tripura' },
  { value: 'UTTAR_PRADESH', label: 'Uttar Pradesh' },
  { value: 'UTTARAKHAND', label: 'Uttarakhand' },
  { value: 'WEST_BENGAL', label: 'West Bengal' },
  { value: 'ANDAMAN_AND_NICOBAR', label: 'Andaman and Nicobar Islands' },
  { value: 'CHANDIGARH', label: 'Chandigarh' },
  { value: 'DADRA_AND_NAGAR_HAVELI', label: 'Dadra and Nagar Haveli and Daman and Diu' },
  { value: 'DAMAN_AND_DIU', label: 'Daman and Diu' },
  { value: 'LAKSHADWEEP', label: 'Lakshadweep' },
  { value: 'DELHI', label: 'Delhi' },
  { value: 'PUDUCHERRY', label: 'Puducherry' }
];
const options = [
  { id: 'yes', title: 'YES' },
  { id: 'no', title: 'NO' },
]

const rentCycleTypeOptions = [
  { id: 'ONE_TO_ONE', title: '1 - 1' },
  { id: 'DATE_TO_DATE', title: 'Date - Date' },
]

const electricityPaymentMethods = [

  { id: 'PRE_PAID', title: 'PRE_PAID' },
  { id: 'IN_RENT', title: 'IN_RENT' },
  { id: 'POST_PAID', title: 'POST_PAID' },
  

]
const subscriptionTypeOptions = [
  { value: 'FREE_TRIAL', label: 'FREE_TRIAL' },
  { value: 'PAID', label: 'PAID' },
]

const subscriptionPeriodOptions = [
  { value: '1', label: '1' },
  { value: '3', label: '3' },
  { value: '6', label: '6' },
  { value: '9', label: '9' },
  { value: '12', label: '12' },
]

// const subscriptionStatusOptions = [
//   { value: 'ACTIVE', label: 'ACTIVE' },
//   { value: 'EXPIRED', label: 'EXPIRED' },
// ]

const paymentModeOptions = [
  { value: 'CASH', label: 'CASH' },
  { value: 'UPI', label: 'UPI' },
  { value: 'NETBANKING', label: 'NETBANKING' },
]

const paymentStatusOptions = [
  { value: 'PENDING', label: 'PENDING' },
  { value: 'SUCCESSFUL', label: 'SUCCESSFUL' },

]

type BranchFormData = {
  // Define the type for your form data
  branchName: string;
  branchAddress: {
    addressLine1: string;
    addressLine2: string;
    landmark: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    latitude: string;
    longitude: string;
  }
  services: string[]
  managerDetails: {
    managerName: string;
    managerContactNumber: string;
  } 
  agreementDetails: {
    noticePeriodInDays: string;
    lockinPeriodInDays: string;
  },
  mealDetails: {
    isMealAvailable?: boolean;
    isMealOptional: boolean|undefined;
    mealAmount: string|undefined;
  },
  monthlyRentCollectionCycleDate: number;
  totalBeds: number;
  livingArrangementType: string;
  visitorsPolicy: {
    areVisitorsAllowed:boolean;
    startTime: string|undefined;
    endTime: string|undefined;
  },
  electricityPolicy: {
    paymentType: string;
    ratePerUnit: string;
  },
  subscriptionDetails: {
    subscriptionStartDate: string;
    subscriptionType: string;
    subscriptionPeriod: string;
    subscriptionPaymentDetails: {
      paymentMode: string|undefined;
      paymentAmount: string|undefined;
      paymentDate: string|undefined;
      paymentStatus: string|undefined;
    } | undefined;
  } | undefined,
  rentCycleType: string;
  allowComplaints: boolean;
  allowOnlinePayments: boolean;
  locationUrl: string;
  // ...
};

type ResponseErrors = {
  [field: string]: string[];
};

const generateTimeOptions = () => {
  const options = [];
  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute <= 30; minute += 30) {
      const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
      options.push(<option key={time} value={time}>{time}</option>);
    }
  }
  return options;
};



export function BranchForm(props: BranchFormProps) {
  // console.log("Branch form props : ", props)
  console.log("initial values : ", props.branch)
  //props.branch.branchAddress.state = "ASSAM"
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<BranchFormData>({
    defaultValues: props.branch
  });
  const [error, setError] = useState("");
  const [responseErrors, setResponseErrors] = useState<ResponseErrors>({});
  const [openingTime, setOpeningTime] = useState('10:00');
  const api = useApi();
  const onBranchSubmit: SubmitHandler<BranchFormData> =  (data: BranchFormData) => {
    console.log("Branch form submitted : ", data);
    
    data.services = data.services || [];
    // @ts-ignore
    data.mealDetails.isMealOptional = data.mealDetails.isMealOptional == "yes" ? 1 : 0
    // @ts-ignore
    data.mealDetails.isMealAvailable = data.mealDetails.isMealAvailable == "yes" ? 1 : 0
    // @ts-ignore
    data.visitorsPolicy.areVisitorsAllowed = data.visitorsPolicy.areVisitorsAllowed == "yes" ? 1 : 0
     // @ts-ignore
     data.allowComplaints = data.allowComplaints == "yes" ? 1 : 0
     // @ts-ignore
     data.allowOnlinePayments = data.allowOnlinePayments == "yes" ? 1 : 0

    if (!props.isEdit && data.subscriptionDetails?.subscriptionPaymentDetails?.paymentDate) {
      const parts = data.subscriptionDetails.subscriptionPaymentDetails?.paymentDate?.split("/")
      const convertedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      data.subscriptionDetails.subscriptionPaymentDetails.paymentDate = convertedDate  
    }

    if (data.subscriptionDetails?.subscriptionStartDate) {
      const parts = data.subscriptionDetails.subscriptionStartDate.split("/")
      const convertedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      data.subscriptionDetails.subscriptionStartDate = convertedDate  
    }


    if (data.visitorsPolicy.endTime) {
      const date = new Date();
      const [hours, minutes] = data.visitorsPolicy.endTime.split(":");
      date.setHours(hours);
      date.setMinutes(minutes);
      const formattedDateTime = date.toISOString();
      data.visitorsPolicy.endTime = formattedDateTime
    }
  
    const requestBody = {
      orgId: props.orgId,
        ...data
    }

    console.log("Request body : ", requestBody)
    
    if(props.isEdit) {
      requestBody.subscriptionDetails = undefined;
      const finalRequestBody = removeEmptyFields(requestBody);
      patchData(`${api.apiUrl}/branch?id=${props.branch.branchId}`, finalRequestBody)
      .then((data) => {
        console.log("patch branch response : ", data.branches[0])
        const updatedBranch = { ...data.branches[0],...{subscriptionDetails:props.branch.subscriptionDetails}}
        props.setSelectedBranch && props.setSelectedBranch(updatedBranch);
        console.log("Branch form : ", props.branches)
        const branchesCopy = _.cloneDeep(props.branches);
        
        const branchIndex = branchesCopy.findIndex((branch) => branch.branchId === data.branches[0].branchId);
        data.branches[0].subscriptionDetails =  branchesCopy[branchIndex].subscriptionDetails
        branchesCopy[branchIndex] = data.branches[0]
        console.log("Branches copy : ", branchesCopy)
        props.setBranches && props.setBranches(branchesCopy)
        props.closeModal && props.closeModal();
      })
      .catch((error) => {
        console.log("Error : ", error)
        console.log("Error to String : ", error.toString())
        setError(error)
      })

    } else {
      const finalRequestBody = removeEmptyFields(requestBody);
      postData(`${api.apiUrl}/branch`, finalRequestBody)
      .then((data) => {
        
        props.closeModal && props.closeModal();
      })
      .catch((error) => {
        console.log("Error : ")
        console.log(error)
        setError(error)
        // const inputObject = error.errors;
        // const outputObject = {};
        // for (const key in inputObject) {
        //   const keys = key.split('.');
        //   let currentObj = outputObject;
        //   for (let i = 0; i < keys.length; i++) {
        //     const currentKey: string = keys[i];
        //     if (i === keys.length - 1) {
        //       currentObj[currentKey] = inputObject[key];
        //     } else {
        //       currentObj[currentKey] = currentObj[currentKey] || {};
        //       currentObj = currentObj[currentKey];
        //     }
        //   }
        // }
      //   const output = error.errors.reduce((acc, error) => {
      //     const { field, defaultMessage } = error;
      //     const fieldPath = field.split('.');
      //     let currentObj = acc;
      //     for (let i = 0; i < fieldPath.length - 1; i++) {
      //         const nestedField = fieldPath[i];
      //         if (!currentObj[nestedField]) {
      //             currentObj[nestedField] = {};
      //         }
      //         currentObj = currentObj[nestedField];
      //     }
      //     const leafField = fieldPath[fieldPath.length - 1];
      //     if (!currentObj[leafField]) {
      //         currentObj[leafField] = [];
      //     }
      //     currentObj[leafField].push(defaultMessage);
      //     return acc;
      // }, {});
        // console.log("Output object : ", outputObject)
        //setResponseErrors(outputObject)
      })
    }

    setResponseErrors({})
    
  }



  const managerName = watch('managerDetails.managerName');
  const isMealAvailable = watch('mealDetails.isMealAvailable') === "yes" ? true : false;
  const isMealOptional = watch('mealDetails.isMealOptional') === "yes" ? true : false;
  const isElectricityIncludedInRent = watch('electricityPolicy.paymentType') === "IN_RENT" ? true : false;
  const isPaidSubscription = watch('subscriptionDetails.subscriptionType') === "PAID" ? true : false;
  const isShortStay = watch('stayType') === "SHORT_STAY" ? true : false;

  
  const areVisitorsAllowed = watch('visitorsPolicy.areVisitorsAllowed') === "yes" || 
                              watch('visitorsPolicy.areVisitorsAllowed') === undefined ||
                              watch('visitorsPolicy.areVisitorsAllowed') === true ? true : false;
  const isSubcriptionPaymentSuccessful = watch('subscriptionDetails.subscriptionPaymentDetails.paymentStatus') === "SUCCESSFUL" ? true : false;
  const isOneToOneRentCycle = watch('rentCycleType') === "ONE_TO_ONE" ? true : false;

  useEffect(
    () => {
      if(props.branch.visitorsPolicy?.endTime) {
        const dateTime = new Date(props.branch.visitorsPolicy.endTime);
        const formattedEndTime = dateTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        setValue("visitorsPolicy.endTime", formattedEndTime)
    }
  },[])
  
const validateManagerContactNumber = (value: string) => {
    if (managerName && !value) {
      return 'Manager contact number is required when manager name is present';
    }
    return true;
  };
  const validateMealAmount = (value: string) => {
    if (isMealOptional && !value) {
      return 'Meal amount is required when meal is optional';
    }
    return true;
  }
  const validateNoticePeriod = (value: string) => {
    if (!isShortStay && value==="") {
      return 'Notice period is required ';
    }
    return true;
  }
  const validateLockinPeriod = (value: string) => {
    if (!isShortStay && value==="") {
      return 'Lockin period is required ';
    } 
    return true;
  }
  const validateElectricityRatePerUnit = (value: string) => {
    if (!isElectricityIncludedInRent && !value) {
      return 'Electricity rate per unit is required when electricity is not included in rent';
    }
    return true;
  }
  const validateSubscriptionPaymentAmount = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment amount is required when subscription is paid';
    }
    return true;
  }
  const validateSubscriptionPaymentDate = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment date is required when subscription is paid';
    }
    return true;
  }
  const validateSubscriptionPaymentMode = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment mode is required when subscription is paid';
    }
    return true;
  }
const validateSubscriptionPaymentStatus = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment status is required when subscription is paid';
    }
    return true;
  }
  const handleStayTypeChange = (event) => {
    if (event.target.value === "SHORT_STAY") {
      // Execute your method here
      setValue("agreementDetails.lockinPeriodInDays", "")
      setValue("agreementDetails.noticePeriodInDays", "")
      console.log("Stay type changed to SHORT_STAY");
      //register("stayType").onChange(event);
    } else {
      clearErrors(["agreementDetails.lockinPeriodInDays", "agreementDetails.noticePeriodInDays"])
    }
  };
  const handleIsMealAvailableChange = (event) => {
    if (event.target.value === "no") {
      setValue("mealDetails.isMealOptional", undefined)
      setValue("mealDetails.mealAmount", undefined)
    } else {
      clearErrors(["mealDetails.mealAmount"])
    }
  }
  const handleIsMealOptionalChange = (event) => {
    if (event.target.value === "no") {
      setValue("mealDetails.mealAmount", undefined)
    } else {
      clearErrors(["mealDetails.mealAmount"])
    }
  }
  const handleAreVisitorsAllowedChange = (event) => {
    if (event.target.value === "no") {
      setValue("visitorsPolicy.endTime", undefined)
    }
  }
  const handleElectricityPaymentTypeChange = (event) => {
    if (event.target.value === "IN_RENT") {
      setValue("electricityPolicy.ratePerUnit", undefined)
    } else {
      clearErrors(["electricityPolicy.ratePerUnit"])
    }
  }
  const handleSubscriptionTypeChange = (event) => {
    if (event.target.value === "FREE_TRIAL") {
      setValue("subscriptionDetails.subscriptionPaymentDetails", {})
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentStatus", "")
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentDate", "")
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentMode", "")
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentAmount", "")
    } else if(event.target.value === "PAID") {
      clearErrors(["subscriptionDetails.subscriptionPaymentDetails.paymentMode", "subscriptionDetails.subscriptionPaymentDetails.paymentAmount", "subscriptionDetails.subscriptionPaymentDetails.paymentDate", "subscriptionDetails.subscriptionPaymentDetails.paymentStatus"])

    }
  }
const handleSubscriptionPaymentStatusChange = (event) => {
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentAmount", "")
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentMode", "")
    if (event.target.value === "SUCCESSFUL") {
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentDate", formattedTodayDate)
    } else {
      setValue("subscriptionDetails.subscriptionPaymentDetails.paymentDate", "")
      clearErrors(["subscriptionDetails.subscriptionPaymentDetails.paymentDate", "subscriptionDetails.subscriptionPaymentDetails.paymentMode", "subscriptionDetails.subscriptionPaymentDetails.paymentAmount"])
    }
  }
  const pincodeRegex = /^[0-9]{6}$/;
  const contactNumberRegex = /^[0-9]{10}$/;
  const totalBedsRegex = /^[0-9]{1,5}$/;
  const today = new Date();
  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedTodayDate = today.toLocaleDateString('en-GB', dateOptions);


  console.log("Form Data : ", watch())

  return (
    <form onSubmit={handleSubmit(onBranchSubmit)}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Branch Details</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            
            <div className="sm:col-span-4">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Branch Name 
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    {...register("branchName", {
                      //required: "Branch name is required",
                      minLength: { value: 3, message: "Branch name should be greater than 3 characters"},
                      maxLength: { value: 50, message: "Branch name should be less than 50 characters"}
                      })}
                    autoComplete="username"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 px-1"
                    
                  />
                </div>
                 { responseErrors.branchName && (<p className="text-sm text-red-500">{responseErrors.branchName}</p>)}
                 { errors.branchName && <p className="text-sm text-red-500">{errors.branchName.message}</p>}
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Address</h2>
        

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                Street address
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.addressLine1", { 
                    required: "Address line 1 is required",
                    minLength: { value: 2, message: "Address line 1 should be greater than 2 characters"},
                    maxLength: { value: 100, message: "Address line 1 should be less than 100 characters"}
                  })}
                  autoComplete="street-address"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.branchAddress?.addressLine1 && (<p className="text-sm text-red-500">{responseErrors.branchAddress?.addressLine1}</p>)}
              { errors.branchAddress?.addressLine1 && <p className="text-sm text-red-500">{errors.branchAddress?.addressLine1.message}</p>}
            </div>

            <div className="col-span-full">
              <label htmlFor="street-address-2" className="block text-sm font-medium leading-6 text-gray-900">
                Street address line 2
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.addressLine2", {
                    maxLength: { value: 100, message: "Address line 2 should be less than 100 characters"}
                  })}
                  autoComplete="street-address-2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.branchAddress?.addressLine2 && <p className="text-sm text-red-500">{responseErrors.branchAddress?.addressLine2}</p> }
              { errors.branchAddress?.addressLine2 && <p className="text-sm text-red-500">{errors.branchAddress?.addressLine2.message}</p>}
            </div>

            <div className="sm:col-span-2 sm:col-start-1">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                Landmark
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.landmark")}
                  autoComplete="landmark"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.branchAddress?.landmark && <p className="text-sm text-red-500">{responseErrors.branchAddress?.landmark}</p> }
              { errors.branchAddress?.landmark && <p className="text-sm text-red-500">{errors.branchAddress?.landmark.message}</p>}
            </div>

            <div className="sm:col-span-2 ">
              <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                City
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.city", {
                    required: "City is required",
                  })}
                  autoComplete="address-level2"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.branchAddress?.city && <p className="text-sm text-red-500">{responseErrors.branchAddress?.city}</p> }
              { errors.branchAddress?.city && <p className="text-sm text-red-500">{errors.branchAddress?.city.message}</p>}
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                State / Province
              </label>
              <select
                {...register("branchAddress.state",{
                  required: "State is required",
                })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option selected value="" disabled>Select an option</option>
                {stateOptions.map(option => <option value={option.value}>{option.label}</option>)}
              </select>
    
              { errors.branchAddress?.state && <p className="text-sm text-red-500">{errors.branchAddress?.state.message}</p>}
            </div>


            <div className="sm:col-span-2">
              <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                Country
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.country",{
                    required: "Country is required",
                  })}
                  autoComplete="address-level1"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.branchAddress?.country && <p className="text-sm text-red-500">{responseErrors.branchAddress?.country}</p> }
              { errors.branchAddress?.country && <p className="text-sm text-red-500">{errors.branchAddress?.country.message}</p>}
            </div>



            <div className="sm:col-span-2">
              <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                ZIP / Postal code
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.pincode",{
                    required: "Pincode is required",
                    pattern: {
                      value: pincodeRegex,
                      message: "pincode should have exactly 6 digits"
                    }
                  })}
                  autoComplete="postal-code"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { errors.branchAddress?.pincode && <p className="text-sm text-red-500">{errors.branchAddress?.pincode.message}</p>}
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="latitude" className="block text-sm font-medium leading-6 text-gray-900">
                Latitude
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.latitude", {
                    required: "latitude is required",
                  })}
                  autoComplete="latitude"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors.branchAddress?.latitude && <p className="text-sm text-red-500">{errors.branchAddress?.latitude.message}</p>}
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="longitude" className="block text-sm font-medium leading-6 text-gray-900">
                Longitude
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("branchAddress.longitude", {
                    required: "longitude is required",
                  })}
                  autoComplete="longitude"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              {errors.branchAddress?.longitude && <p className="text-sm text-red-500">{errors.branchAddress?.longitude.message}</p>}
            </div>

          </div>
        </div>

        {/* <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Manager Details</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                Manager name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("managerDetails.managerName",{
                    minLength: { value: 3, message: "Manager name should be greater than 3 characters"},
                    maxLength: { value: 50, message: "Manager name should be less than 50 characters"}
                  })}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.managerDetails?.managerName && <p className="text-sm text-red-500">{responseErrors.managerDetails?.managerName}</p> }
              { errors.managerDetails?.managerName && <p className="text-sm text-red-500">{errors.managerDetails?.managerName.message}</p>}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                Contact number
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("managerDetails.managerContactNumber",{
                    validate: validateManagerContactNumber,
                    pattern: {
                      value: contactNumberRegex,
                      message: "contact number should have exactly 10 digits"
                    }
                  })}
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.managerDetails?.managerContactNumber &&  <p className="text-sm text-red-500">{responseErrors.managerDetails?.managerContactNumber}</p> }
              { errors.managerDetails?.managerContactNumber && <p className="text-sm text-red-500">{errors.managerDetails?.managerContactNumber.message}</p>}
            </div>

          </div>
        </div> */}


        <div className="border-b border-gray-900/10 pb-12">
            <div className="sm:col-span-3 mt-10">
              <label htmlFor="rent-start-date" className="block text-sm font-medium text-gray-700">
                Stay Type
              </label>
              <select
                {...register("stayType",{
                  onChange: (event) => {
                    handleStayTypeChange(event);
                  }
                })}
                className="mt-2 w-60  pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="LONG_STAY">Long Stay</option>
                <option value="SHORT_STAY">Short Stay</option>
                <option value="BOTH">Both</option>
              </select>
              { responseErrors.stayType && <p className="text-sm text-red-500">{responseErrors.stayType}</p> }
              { errors.stayType && <p className="text-sm text-red-500">{errors.stayType.message}</p>}
            </div>
          </div>
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Pg Category</h2>
          <div className=" grid grid-cols-1 gap-x-3 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3 mt-10">
              <label htmlFor="rent-start-date" className="block text-sm font-medium text-gray-700">
                Living Arrangement
              </label>
              <select
                {...register("livingArrangementType")}
                className="mt-2 w-60 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="GIRLS">Girls</option>
                <option value="BOYS"> Boys</option>
                <option value="CO_LIVING">Co-Living</option>
              </select>
              { responseErrors.livingArrangementType &&  <p className="text-sm text-red-500">{responseErrors.livingArrangementType}</p> }
              { errors.livingArrangementType && <p className="text-sm text-red-500">{errors.livingArrangementType.message}</p>}
            </div>
              
          </div>
        </div>
        {!isShortStay && <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Agreement Details</h2>
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                Notice Period
              </label>
              <select
                
                {...register("agreementDetails.noticePeriodInDays",{
                  validate: validateNoticePeriod
                })}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option  value="" disabled>Select an option</option>
                <option selected value="15">15</option>
                <option value="0">0</option>
                <option value="30">30</option>
                <option value="45">45</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="180">180</option>
              </select>
              { responseErrors.agreementDetails?.noticePeriodInMonths && <p className="text-sm text-red-500">{responseErrors.agreementDetails?.noticePeriodInMonths}</p> }
              { errors.agreementDetails?.noticePeriodInMonths && <p className="text-sm text-red-500">{errors.agreementDetails?.noticePeriodInMonths.message}</p>}
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lockin-period" className="block text-sm font-medium text-gray-700">
                Lockin Period
              </label>
              <select
                
                {...register("agreementDetails.lockinPeriodInDays", {
                  validate: validateLockinPeriod
                })}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                 <option value="" disabled>Select an option</option>
                <option selected value="0">0</option>
                <option value="30">30</option>
                <option value="60">60</option>
                <option value="90">90</option>
                <option value="180">180</option>
                <option value="270">270</option>
                <option value="360">360</option>
              </select>
              { responseErrors.agreementDetails?.lockinPeriodInMonths &&  <p className="text-sm text-red-500">{responseErrors.agreementDetails?.lockinPeriodInMonths}</p> }
              { errors.agreementDetails?.lockinPeriodInMonths && <p className="text-sm text-red-500">{errors.agreementDetails?.lockinPeriodInMonths.message}</p>}
            </div>
                
          </div>
        </div>}

        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">Other details</h2>
          <div className="flex items-center mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">

          <div className="sm:col-span-3">
            <label
              htmlFor="owner-name"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              services
            </label>
            <div className="mt-2">
            <Controller
            control={control}
            name={"services"}
            
            render={({ field }) => (
              <Select 
                isMulti 
                {...field}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    field.onChange(options.map((opt) => opt.value));
                  }
                }}
                value={field.value ? servicesOptions.filter((c) => field.value?.includes(c.value)) : null}
                options={servicesOptions}
                components={{
                  Option: InputOption
                }}
              />
            )}
          />
            </div>
            { responseErrors.services && <p className="text-sm text-red-500">{responseErrors.services}</p> }
            { errors.services && <p className="text-sm text-red-500">{errors.services.message}</p>}
          </div>

            <label className="text-base font-medium text text-gray-900">Is Meal available in PG ?</label>
            <fieldset >
              <legend className="sr-only">Is Meal available in PG ? </legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
                {options.map((option) => (
                  <div key={option.id} className="flex items-center">
                    <input
                      id={option.id}
                      value={option.id}
                      {...register("mealDetails.isMealAvailable",{
                        onChange: (event) => {
                          handleIsMealAvailableChange(event);
                        }
                      })}
                      type="radio"
                      defaultChecked={(getValues('mealDetails.isMealAvailable') == true ? "yes" : "no") === option.id}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                      {option.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

          { isMealAvailable && <div className="flex items-center mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
            <label className="text-base font-medium text text-gray-900">Is Meal Optional ?</label>
            <fieldset >
              <legend className="sr-only">Is Meal Optional ? </legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
                {options.map((option) => (
                  <div key={option.id} className="flex items-center">
                    <input
                      id={option.id}
                      value={option.id}
                     {...register("mealDetails.isMealOptional",{
                      onChange: (event) => {
                        handleIsMealOptionalChange(event);
                      }
                    })}
                      type="radio"
                      defaultChecked={(getValues('mealDetails.isMealOptional') == true ? "yes" : "no") === option.id}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                      {option.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            { responseErrors.mealDetails?.isMealOptional &&  <p className="text-sm text-red-500">{responseErrors.mealDetails?.isMealOptional}</p> }
            { errors.mealDetails?.isMealOptional && <p className="text-sm text-red-500">{errors.mealDetails?.isMealOptional.message}</p>}
          </div>}

            { isMealAvailable && isMealOptional &&<div className="sm:col-span-3 mt-10">
              <label htmlFor="meal-amount" className="block text-sm font-medium leading-6 text-gray-900">
                Meal amount 
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("mealDetails.mealAmount",{
                    validate: validateMealAmount
                  })}
                  id="meal-amount"
                  autoComplete="meal-amount"
                  className="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.mealDetails?.mealAmount && <p className="text-sm text-red-500">{responseErrors.mealDetails?.mealAmount}</p> }
              { errors.mealDetails?.mealAmount && <p className="text-sm text-red-500">{errors.mealDetails?.mealAmount.message}</p>}
            </div>}


            <div className="flex items-center mt-10 grid grid-cols-1 gap-x-9 gap-y-8 sm:grid-cols-3">
            <label className="text-base font-medium text text-gray-900">Rent Cycle Type : </label>
            <fieldset >
              <legend className="sr-only">Rent Cycle Type </legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-50">
                {rentCycleTypeOptions.map((option) => (
                  <div key={option.id} className="flex items-center">
                    <input
                      id={option.id}
                      value={option.id}
                     {...register("rentCycleType",{
                      onChange: (event) => {
                        handleIsMealOptionalChange(event);
                      }
                    })}
                      type="radio"
                      defaultChecked={getValues('rentCycleType') === option.id}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                      {option.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>


          
          <div className='flex flex-col'>
            {/* {!isOneToOneRentCycle && <div className="sm:col-span-3 mt-10">
              <label htmlFor="rent-start-date" className="block text-sm font-medium text-gray-700">
                Monthly rent start date
              </label>
              <select
                 {...register("monthlyRentCollectionCycleDate",{required:"Monthly rent start date is required"})}
                className="mt-2 w-30  pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option>5</option>
                <option>5</option>
                <option>7</option>
                <option>10</option>
                <option>15</option>
              </select>
              { responseErrors.monthlyRentCollectionCycleDate &&  <p className="text-sm text-red-500">{responseErrors.monthlyRentCollectionCycleDate}</p> }
              { errors.monthlyRentCollectionCycleDate && <p className="text-sm text-red-500">{errors.monthlyRentCollectionCycleDate.message}</p>}
            </div>} */}
          



            <div className="flex items-center mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
              <label className="text-base font-medium text text-gray-900">Allow Online payments ?</label>
              <fieldset >
                <legend className="sr-only">Allow Online payments ? </legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
                  {options.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        id={option.id}
                        value={option.id}
                      {...register("allowOnlinePayments",{
                        onChange: (event) => {
                          handleIsMealOptionalChange(event);
                        }
                      })}
                        type="radio"
                        defaultChecked={(getValues('allowOnlinePayments') == true ? "yes" : "no") === option.id}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {option.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>

            <div className="flex items-center mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
              <label className="text-base font-medium text text-gray-900">Allow Complaints ?</label>
              <fieldset >
                <legend className="sr-only">Allow Complaints ? </legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
                  {options.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        id={option.id}
                        value={option.id}
                      {...register("allowComplaints",{
                        onChange: (event) => {
                          handleIsMealOptionalChange(event);
                        }
                      })}
                        type="radio"
                        defaultChecked={(getValues('allowComplaints') == true ? "yes" : "no") === option.id}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {option.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>

            <div className="sm:col-span-3 mt-10">
              <label htmlFor="meal-amount" className=" text-sm font-medium leading-6 text-gray-900">
                Total beds available
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  {...register("totalBeds", {
                    pattern: {
                      value: totalBedsRegex,
                      message: "total beds should have 1-5 digits"
                    }
                  })}
                  autoComplete="total-beds"
                  className="  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
              { responseErrors.totalBeds &&  <p className="text-sm text-red-500">{responseErrors.totalBeds}</p> }
              { errors.totalBeds && <p className="text-sm text-red-500">{errors.totalBeds.message}</p>}
            </div>
            </div>
            <div className="flex items-center mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
              <label className="text-base font-medium text text-gray-900">Visitors Allowed ?</label>
              <fieldset >
                <legend className="sr-only">Visitors Allowed </legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
                  {options.map((option) => (
                    <div key={option.id} className="flex items-center">
                      <input
                        id={option.id}
                        value={option.id}
                        {...register("visitorsPolicy.areVisitorsAllowed", {
                          onChange: (event) => {
                            handleAreVisitorsAllowedChange(event);
                          }
                        })}
                        type="radio"
                        defaultChecked={(getValues('visitorsPolicy.areVisitorsAllowed') == true ? "yes" : "no") === option.id}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {option.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              { responseErrors.visitorsPolicy?.areVisitorsAllowed &&  <p className="text-sm text-red-500">{responseErrors.visitorsPolicy?.areVisitorsAllowed}</p> }
              { errors.visitorsPolicy?.areVisitorsAllowed && <p className="text-sm text-red-500">{errors.visitorsPolicy?.areVisitorsAllowed.message}</p>}
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {areVisitorsAllowed && <div className="sm:col-span-3">
              <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                End time
              </label>
              <select
                {...register("visitorsPolicy.endTime")}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                {generateTimeOptions()}
              </select>
              { responseErrors.visitorsPolicy?.endTime && <p className="text-sm text-red-500">{responseErrors.visitorsPolicy?.endTime}</p> }
              { errors.visitorsPolicy?.endTime && <p className="text-sm text-red-500">{errors.visitorsPolicy?.endTime.message}</p>}
            </div>}
          </div>



          <div className="flex items-center mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
              <label className="text-base font-medium text text-gray-900">Electricity Payment type</label>
              <fieldset >
                <legend className="sr-only">Electricity Payment Type </legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-5">
                  {electricityPaymentMethods.map((electricityPaymentMethod) => (
                    <div key={electricityPaymentMethod.id} className="flex items-center">
                      <input
                        id={electricityPaymentMethod.id}
                        value={electricityPaymentMethod.id}
                        {...register("electricityPolicy.paymentType", {
                          onChange: (event) => {
                            handleElectricityPaymentTypeChange(event);
                          }
                        })}
                        type="radio"
                        defaultChecked = {getValues('electricityPolicy.paymentType') === electricityPaymentMethod.id}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      />
                      <label htmlFor={electricityPaymentMethod.id} className="ml-3 block text-sm font-medium text-gray-700">
                        {electricityPaymentMethod.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              { responseErrors.electricityPolicy?.paymentType &&  <p className="text-sm text-red-500">{responseErrors.electricityPolicy?.paymentType}</p> }
              { errors.electricityPolicy?.paymentType && <p className="text-sm text-red-500">{errors.electricityPolicy?.paymentType.message}</p>}
            </div>
            {!isElectricityIncludedInRent && <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="rate-per-unit" className="block text-sm font-medium leading-6 text-gray-900">
                  Rate per unit
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    {...register("electricityPolicy.ratePerUnit", {
                      validate: validateElectricityRatePerUnit
                    })}
                    id="rate-per-unit"
                    autoComplete="rate-per-unit"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                { responseErrors.electricityPolicy?.ratePerUnit &&  <p className="text-sm text-red-500">{responseErrors.electricityPolicy?.ratePerUnit}</p> }
                { errors.electricityPolicy?.ratePerUnit && <p className="text-sm text-red-500">{errors.electricityPolicy?.ratePerUnit.message}</p>}
              </div>
              
            </div>}

            { !props.isEdit && 
            
            
            <div className="border-t border-gray-900/10 pb-12 mt-10">
              <h2 className="text-base font-semibold leading-7 mt-3 text-gray-900">Subscription Details</h2>
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


              <div className="sm:col-span-3">
                <label
                htmlFor="room-sharing-type"
                className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Subscription Start Date
                </label>
                <div >
                <Controller
                      control={control}
                      defaultValue = {formattedTodayDate}
                      name={"subscriptionDetails.subscriptionStartDate"}
                      
                      render={({ field }) => {
                        //field.value = field.value || new Date().toLocaleDateString();
                        return (
                          <Datepicker 
                          value={field.value}
                          onSelectedDateChanged={(date) => field.onChange(date.toLocaleDateString())}
                      />
                        )
                      }}
                  />
                </div>
                { responseErrors.subscriptionDetails?.subscriptionStartDate &&  <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionStartDate}</p> }
                { errors.subscriptionDetails?.subscriptionStartDate && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionStartDate.message}</p>}
              </div>

                <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                    Subscription Type
                  </label>
                  <select
                    {...register("subscriptionDetails.subscriptionType", {
                      onChange: (event) => {
                        handleSubscriptionTypeChange(event);
                      }
                    })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {subscriptionTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionDetails?.subscriptionType &&  <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionType}</p> }
                  { errors.subscriptionDetails?.subscriptionType && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionType.message}</p>}
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Period In Months
                  </label>
                  <select
                    {...register("subscriptionDetails.subscriptionPeriod")}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {subscriptionPeriodOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionDetails?.subscriptionPeriod && <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionPeriod}</p> }
                  { errors.subscriptionDetails?.subscriptionPeriod && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionPeriod.message}</p>}
                </div>


                {/* <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Status
                  </label>
                  <select
                    {...register("subscriptionDetails.subscriptionStatus")}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {subscriptionStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionDetails?.subscriptionStatus?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors.subscriptionDetails?.subscriptionStatus && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionStatus.message}</p>}
                </div> */}

                {isPaidSubscription &&<div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Payment Status
                  </label>
                  <select
                    {...register("subscriptionDetails.subscriptionPaymentDetails.paymentStatus", {
                      validate: validateSubscriptionPaymentStatus,
                      onChange: (event) => {
                        handleSubscriptionPaymentStatusChange(event);
                      }
                    })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option selected value="" disabled>Select an option</option> 
                    {paymentStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentStatus &&  <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentStatus}</p> }
                  { errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentStatus && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentStatus.message}</p>}
                </div>}

                {isPaidSubscription && isSubcriptionPaymentSuccessful && <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                    Subscription Payment Amount
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      {...register("subscriptionDetails.subscriptionPaymentDetails.paymentAmount",{
                        validate: validateSubscriptionPaymentAmount,
                      })}
                      id="rate-per-unit"
                      autoComplete="rate-per-unit"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  { responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentAmount &&  <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentAmount}</p> }
                  { errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentAmount && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentAmount.message}</p>}
                </div>}

                {isPaidSubscription && isSubcriptionPaymentSuccessful && <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Payment Mode
                  </label>
                  <select
                    {...register("subscriptionDetails.subscriptionPaymentDetails.paymentMode", {
                      validate: validateSubscriptionPaymentMode
                    })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                     <option selected value="" disabled>Select an option</option>
                    {paymentModeOptions.map((option, index) => (
                      <option  key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentMode &&  <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentMode }</p> }
                  { errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentMode && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentMode.message}</p>}
                </div>}

                {isPaidSubscription && isSubcriptionPaymentSuccessful &&<div className="sm:col-span-3">
                <label
                htmlFor="room-sharing-type"
                className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Payment Date
                </label>
                <div >
                <Controller
                      control={control}
                      defaultValue = {formattedTodayDate}
                      name={"subscriptionDetails.subscriptionPaymentDetails.paymentDate"}
                      render={({ field }) => {
                        //field.value = field.value || new Date().toLocaleDateString();
                        return (
                          <Datepicker 
                          value={field.value}
                          onSelectedDateChanged={(date) => field.onChange(date.toLocaleDateString())}
                      />
                        )
                      }}
                  />
                </div>
                { responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentDate && <p className="text-sm text-red-500">{responseErrors.subscriptionDetails?.subscriptionPaymentDetails?.paymentDate}</p> }
                { errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentDate && <p className="text-sm text-red-500">{errors.subscriptionDetails?.subscriptionPaymentDetails?.paymentDate.message}</p>}
                </div>}
              </div>
            </div> }

            { error && 
            <div className="rounded-md bg-red-50 p-2 mt-4">
              <div className="flex ">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                  </div>
                </div>
              </div>
            </div>}

        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button type="button" onClick={() => props.closeModal()} className="text-sm font-semibold leading-6 text-gray-900">
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  )
}
