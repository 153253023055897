import React from "react";
import { useContext, createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies';
import { postData } from "../utils/apiUtil";
import { useApi } from "./ApiProvider";
const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const api = useApi();
  const cookieKey = 'auth'
  const cookieVal = read_cookie(cookieKey)
  const [screen, setScreen] = useState(cookieVal.length == 0 ? "auth" : "dashboard");
  
  console.log("Screen : ", screen)
  
  const loginAuth = async (data) => {
    // login implementation
    postData(`${api.apiUrl}/login`, data)
    .then(data => {
        bake_cookie(cookieKey, 'test');
        setScreen("dashboard")
        navigate("/");
    })
    .catch(err => {
        console.log("error message : ",err)
    })
  };
  const logoutAuth = async () => {
    // logout implementation
    setScreen("auth");
    navigate("/login");
    delete_cookie(cookieKey)

  };

  return (
    <AuthContext.Provider value={{ screen, loginAuth, logoutAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
export const useAuth = () => {
  return useContext(AuthContext);
};