
import React, {useState} from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { Floor } from "../views/FloorDetails";
import { RoomType } from "../views/RoomTypeDetails";
import { postData, putData } from "../../utils/apiUtil";
import { useApi } from "../ApiProvider";
import { Room } from "../types";
import { InputOption } from "../InputOption";
import { ExclamationIcon } from '@heroicons/react/outline'

const roomTypesOptions = [
  { label: "Single Sharing", value: "SINGLE_SHARING" },
  { label: "Double Sharing", value: "DOUBLE_SHARING" },
  { label: "Triple Sharing", value: "TRIPLE_SHARING" },
  { label: "Four Sharing", value: "FOUR_SHARING" },
  { label: "Five Sharing", value: "FIVE_SHARING" },
];

const amenitiesOptions = [
  { label: "Television", value: "TELEVISION" },
  { label: "Table", value: "TABLE" },
  { label: "Chair", value: "CHAIR" },
  { label: "Air Conditioning", value: "AIR_CONDITIONING" },
  { label: "Cooler", value: "COOLER" },
  { label: "Fan", value: "FAN" },
  { label: "Almirah / Cupboard", value: "CUPBOARD" },
  { label: "Bed with Mattress", value: "BED_MATTRESS" },
  { label: "Geyser", value: "GEYSER" },
  { label: "Balcony", value: "BALCONY" },
  { label: "Bedsheets & Pillow", value: "BEDSHEETS_PILLOW" },
  { label: "Blanket", value: "BLANKET" },
  { label: "Bucket & Mug", value: "BUCKET_MUG" },
  { label: "Kitchen Area", value: "KITCHEN_AREA" },
  { label: "Induction Cooktop", value: "INDUCTION_COOKTOP" },
  { label: "Bookshelf", value: "BOOKSHELF" },
  { label: "Refrigerator / Fridge", value: "REFRIGERATOR" },
  { label: "Microwave", value: "MICROWAVE" },
  { label: "Mosquitoes Repellent", value: "MOSQUITO_REPELLENT" },
];

type RoomTypeFormData = {
    roomSharingType: string,
    securityAmount: number,
    rentAmount: number,
    rentPerDayAmount: number,
    amenities: string[],
    floors: string[]
};

type EditRoomTypeFormProps = {
    roomTypeId: number,
    roomSharingType: string,
    securityAmount: number,
    rentAmount: number,
    rentPerDayAmount: number,
    amenities: string[],
    mappedFloors:number[],
    floors: Floor[],
    closeModal?: () => void;
}

export function EditRoomTypeForm(props: EditRoomTypeFormProps) {
  console.log("Edit Room Type Props : ", props)
  const defaultValues = {
    securityAmount: props.securityAmount,
    rentAmount: props.rentAmount,
    rentPerDayAmount: props.rentPerDayAmount,
    amenities: props.amenities,
    roomSharingType: props.roomSharingType,
    floors: props.floors.map(floor => {
      if(props.mappedFloors?.includes(floor.floorId)) {
        return floor.floorId
      }
    }).filter(value => value != undefined)
  }
  console.log("Default values : ", defaultValues)
  const floorOptions = props.floors.map(floor => ({
    label: floor.floorName,
    value: floor.floorId
  }));

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors, dirtyFields },
  } = useForm<RoomTypeFormData>({defaultValues});
  const [error, setError] = useState("");
  const api = useApi()
  const onEditRoomTypeSubmit: SubmitHandler<RoomTypeFormData> = async (data: RoomTypeFormData) => {
    console.log("Edit Room Type Form Data : ", data);
    console.log("Dirty fields : ", dirtyFields["floors"])
    const requestData = {
        roomTypeId: props.roomTypeId,
        securityAmount: data.securityAmount,
        rentAmount: data.rentAmount,
        rentPerDayAmount: data.rentPerDayAmount,
        amenities: data.amenities,
        roomSharingType: data.roomSharingType
    }
    console.log("Request Data : ", requestData);
    putData(`${api.apiUrl}/room-type/by-id`, requestData)
    .then((response) => {
          if(dirtyFields["floors"]) {
            const updateMappingsRequestData = {
              roomTypeId: props.roomTypeId,
              floorIds: data.floors
            }
            putData(`${api.apiUrl}/room-type/mapping`, updateMappingsRequestData)
            .then(updateMappingResponse => {
              console.log("Mappings updated successfully")
              props.closeModal && props.closeModal();
            })
            .catch(error => {
              setError(error)
            })
          } else {
          props.closeModal && props.closeModal();
          }
     })
     .catch(error => {
        setError(error)
     }) 

  };

  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onEditRoomTypeSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Edit Room Type
        </h2>

        <div className="mt-10 flex flex-col  sm:flex-col ">
          <div className="flex-1 max-w-sm">
            <label
              htmlFor="roomTypeId"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Room Sharing Type
            </label>
            <div className="mt-2">
            <Controller
                control={control}
                rules={{ required: true }}
                name={"roomSharingType"}
                render={({ field }) => {
                    return (
                        <Select  
                          options={roomTypesOptions} 
                          {...field}
                          onChange = {(val) => field.onChange(val?.value)}
                          value = { field.value ? roomTypesOptions.find((c) => c.value === field.value) : null}
                          defaultValue={null} 
                          
                        />
                    )
                }}
            />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
                <label
                htmlFor="owner-name"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Amenities
                </label>
                <div className="mt-2">
                    <Controller
                    control={control}
                    name={"amenities"}
                    render={({ field }) => (
                    <Select 
                    isMulti 
                    {...field}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => {
                      if (Array.isArray(options)) {
                        field.onChange(options.map((opt) => opt.value));
                      }
                    }}
                    value={field.value ? amenitiesOptions.filter((c) => field.value.includes(c.value)) : null}
                    options={amenitiesOptions}
                    components={{
                      Option: InputOption
                    }}
                     />
                    )}/>
                </div>
            </div>

          <div className="flex-1 max-w-sm">
            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                Rent Amount
            </label>
            <div className="mt-1">
                <input
                {...register("rentAmount")}
                type="text"
                id="comment"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={''}
                />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Rent Amount Per Day
            </label>
            <div className="mt-2">
              <input
                type="tel"
                id="phone-number"
                autoComplete="tel"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                {...register("rentPerDayAmount")}
              />
            </div>
          </div>


          <div className="flex-1 max-w-sm">
            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                Security Amount
            </label>
            <div className="mt-1">
                <input
                {...register("securityAmount")}
                type="text"
                id="comment"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={''}
                />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
                <label
                htmlFor="owner-name"
                className="block text-sm font-medium leading-6 mt-6 text-gray-900"
                >
                Floors
                </label>
                <div className="mt-2">
                    <Controller
                    control={control}
                    name={"floors"}
                    render={({ field }) => (
                    <Select 
                    isMulti 
                    {...field}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(options) => {
                      if (Array.isArray(options)) {
                        field.onChange(options.map((opt) => opt.value));
                      }
                    }}
                    value={field.value ? floorOptions.filter((c) => field.value.includes(c.value)) : null}
                    options={floorOptions}
                    components={{
                      Option: InputOption
                    }}
                     />
                    )}/>
                </div>
            </div>

            { error && 
            <div className="rounded-md bg-red-50 p-2 mt-4">
              <div className="flex ">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                  </div>
                </div>
              </div>
            </div>}
          
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{props.closeModal && props.closeModal()}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




