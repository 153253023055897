import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { FloorForm } from './forms/FloorForm';
import { Modal } from './Modal';
import { OrgForm } from './forms/OrgForm';
import { deleteData, getData, postData, postZaakpayData } from '../utils/apiUtil';
import { apiUrl } from '../config';
import DeleteForm from './forms/DeleteForm';
import MenuItems from './MenuItems';
import { useApi } from './ApiProvider';

type OrgData = {
    orgId: number;
    brandName: string;
    ownerName?: string;
    phoneNumber?: string;
    onboardingStatus?: string;
    branches?: Branch[];
    subscription?: {
      subscriptionId?: number,
      subscriptionType: string,
      subscriptionPeriod: number,
      subscriptionStatus: string
    },
    subscriptionPayment: {
      paymentId?: number,
      paymentMode: string,
      paymentAmount: number,
      paymentDateTime: string,
      paymentStatus: string,
      paymentDescription: string
    }
    amount?: number;
    paymentMode?: string;
    paymentDate?: string;
    status?: string;
};

interface Branch {
  id: number;
  branchName: string;
  managerName?: string;
}



const orgData: OrgData[] = [
    { 
      orgId:1,
      brandName: 'Apna Home', 
      ownerName: 'Ashish Belide ', 
      phoneNumber: '+918328205208', 
      branches: [
        {
            id: 1,
            branchName: 'Apna Home 1',    
        },
        {
            id: 2,
            branchName: 'Apna Home 2',
        }
      ],
      subscription: '6 months',
      amount: 1000,
      paymentMode: 'UPI',
      paymentDate: '01/01/2021',
      status: 'Active'
    },
    { 
      orgId:2,
      brandName: 'Acme Inc.', 
      ownerName: 'John Doe', 
      phoneNumber: '+1-555-555-5555', 
      branches: [
        {
            id: 1,
            branchName: 'Acme Inc. 1',   
            managerName: 'John Doe', 
        }
      ],
      subscription: '1 year',
      amount: 5000,
      paymentMode: 'Credit Card',
      paymentDate: '02/01/2021',
      status: 'Active'
    },
    { 
      orgId:3,
      brandName: 'Globex Corporation', 
      ownerName: 'Hank Scorpio', 
      phoneNumber: '+1-555-123-4567', 
      branches: [
        {
            id: 1,
            branchName: 'Globex Corporation 1',    
            managerName: 'Hank Scorpio',
        }
      ],
      subscription: '3 months',
      amount: 2500,
      paymentMode: 'PayPal',
      paymentDate: '03/01/2021',
      status: 'Inactive'
    },
    // add more test data objects here
];
  
  export function Dashboard() {

    const navigate = useNavigate();
    const api = useApi()
    
    const [showModal, setShowModal] = useState(false);
    const [orgData, setOrgData] = useState([] as OrgData[]);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState<OrgData>();


    const deleteOrg = async (org: OrgData|undefined) => {
      await deleteData(`${api.apiUrl}/org?id=${org?.id}`)
      .then((data) => {
        console.log('Data : ', data);
      })
      .catch((error) => {
        // Handle the error
      });
    }

    const getOrgs = async () => {
      await getData(`${api.apiUrl}/orgs`)
      .then((data) => {
        console.log('Data : ', data);
        setOrgData(data.orgs);
      })
      .catch((error) => {
        // Handle the error
      });
    }
    
    useEffect(() => {
      getOrgs();
    }, [api]);

    const handleOnboardClick = () => {
      setShowModal(true);
    };

    const handleOrgClick = (org: OrgData) => {
        navigate('/org-details', { state: { org } });
    };

    const handleEditOrgClick = (org: OrgData) => {
      setIsEdit(true);
      setSelectedOrg(org);
      setShowModal(true);
    };

    const handleDeleteOrgClick = (org: OrgData) => {
      setIsDelete(true);
      setSelectedOrg(org);
      setShowModal(true);
    }

    function objectToQueryParams(obj) {
      return Object.keys(obj)
          .map(key => `${key}=${obj[key]}`)
          .join('&');
    }

    const handlePayNow = () => {
      console.log("Handle Pay Now")
      const zaakpayUrl = 'https://zaakstaging.zaakpay.com/api/paymentTransact/V8'
      const body = {
        amount: 5000,
        buyerEmail: 'ashish@stayflo.in',
        currency:'INR',
        merchantIdentifier: 'fb2016ffd3a64b2994a6289dc2b671a4',
        orderId: 'ZAAK20240430_4',
        checksum: '07e094988e83892a120bab6fcdccf29b27c1d05623d72d109d7ab5fa2cf3ea50'
      }
      console.log("Query params : ", objectToQueryParams(body))
      window.location.href = zaakpayUrl + '?' + objectToQueryParams(body)
    }
    console.log("Api URL : ", api.apiUrl)

    return (
      <>
      {showModal && isDelete &&
            (
                <DeleteForm 
                showModal={showModal} 
                title={" Delete Organization"} 
                onDelete={() => {
                  deleteOrg(selectedOrg)
                  .then(()=>{
                    getOrgs()
                      .then(()=>{
                        setIsDelete(false)
                        setShowModal(false)
                        setSelectedOrg(undefined)
                      })
                  })
                }}
                onClose={() => {
                    setShowModal(false)
                    setIsDelete(false)
                    setSelectedOrg(undefined)
                  }} />
                  
            )}
      {showModal && !isDelete &&
            (
                <Modal showModal={showModal} title={""} onClose={() => {
                    setShowModal(false)
                    setIsEdit(false)
                  }} >
                    <OrgForm 
                    org={selectedOrg}
                    closeModal={()=>{
                      getOrgs()
                      .then(()=>{
                        setIsEdit(false)
                        setShowModal(false)
                        setSelectedOrg(undefined)
                      })
                      
                    }} />
                </Modal>
            )}
      <div className="px-4 mt-5 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Listed PG's </h1>

          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <MenuItems setEnv={api.setApiUrl} />
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              onClick={handleOnboardClick}
            >
              Onboard PG
            </button>
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto m-5"
              onClick={handlePayNow}
            >
              Pay Now
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div id="zaakpay"></div>
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                      >
                        Brand Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Owner
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Contact Number
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Branches
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Onboarding status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                        <span className="sr-only">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {orgData.map((data) => (
                      <tr key={data.contactNumber}>
                        <td 
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                            onClick={() => handleOrgClick(data)}
                        >
                          {data.brandName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{!data.ownerName ? '-' : data.ownerName }</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{!data.phoneNumber ? '-' : data.phoneNumber}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{!data.branches?.length ? '-' : data.branches.length}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{!data.onboardingStatus ? '-' : data.onboardingStatus}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <a 
                              href="#" 
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick = {() => handleEditOrgClick(data)}>
                                Edit<span className="sr-only">, {data.ownerName}</span>
                            </a>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <a 
                              href="#" 
                              className="text-red-600 hover:text-indigo-900"
                              onClick = {() => handleDeleteOrgClick(data)}>
                                Delete<span className="sr-only">, {data.ownerName}</span>
                            </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  