export function removeEmptyFields(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      return obj.map(removeEmptyFields);
    }
  
    const result: any = {};
  
    for (const key in obj) {
      const value = removeEmptyFields(obj[key]);
  
      if (value !== undefined && value !== '') {
        result[key] = value;
      }
    }
  
    return result;
  }