
import React,{useState} from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { Floor } from "../views/FloorDetails";
import { RoomType } from "../views/RoomTypeDetails";
import { postData, putData } from "../../utils/apiUtil";

import { Room } from "../types";
import { ExclamationIcon } from '@heroicons/react/outline'
import { useApi } from "../ApiProvider";
type RoomFormData = {
  // Define the type for your form data
  roomNumber: string;
  floorId: number
  roomTypeId: number;
// ...
};

type EditRoomFormProps = {
    room: Room;
    floorId: number,
    roomTypeId: number,
    floors: any[];
    roomTypes: any[],
    roomTypesByFloor: any[],
    closeModal?: () => void;
    onSuccess: (response: any)  => void;
}

export function EditRoomForm(props: EditRoomFormProps) {
  console.log("Edit Room Props : ", props)

  const defaultValues = {
    roomNumber: props.room.roomNumber,
    floorId: props.floorId,
    roomTypeId: props.roomTypeId
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<RoomFormData>({defaultValues});
  const [error, setError] = useState("");
  const api = useApi()
  const floorOptions = props.floors.map((floor) => {
    return {
      value: floor.floorId,
      label: floor.floorName,
    };
  })
  
  let roomTypesOptions = props.roomTypesByFloor[props.floorId]?.map((roomType) => {
    return {
      label: roomType.roomSharingType + ", " + roomType.amenities.length + " amenities",
      value: roomType.roomTypeId
    };
  })
  console.log("RoomTypes Options : ", roomTypesOptions)
  const onEditRoomSubmit: SubmitHandler<RoomFormData> = async (data: RoomFormData) => {
    
    const requestData = {
        roomId: props.room.roomId,
        roomNumber: data.roomNumber,
        floorId: data.floorId,
        roomTypeId: data.roomTypeId
    }
    putData(`${api.apiUrl}/room-details/by-id`, requestData)
    .then((response) => {
        props.closeModal && props.closeModal();
        props.onSuccess(response);
    })
    .catch(error => {
      setError(error)
    })
  };
  console.log("Form values : ", watch() )
  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onEditRoomSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Edit Room
        </h2>

        <div className="mt-10 flex flex-col  sm:flex-col ">
        <div className="flex-1 max-w-sm">
            <label
              htmlFor="owner-name"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Floor
            </label>
            <div className="mt-2">
            <Controller
                control={control}
                rules={{ required: true }}
                name={"floorId"}
                render={({ field }) => (
                <Select  
                    options={floorOptions} 
                    {...field}
                    onChange = {(val) => {
                        setValue("roomTypeId", null); 
                        roomTypesOptions = props.roomTypesByFloor[val.value]?.map(roomType => {
                          return {
                            label: roomType.roomSharingType + ", " + roomType.amenities.length + " amenities",
                            value: roomType.roomTypeId
                          }
                        })
                        field.onChange(val?.value)
                    }}
                    value = {floorOptions.find((c) => c.value === field.value)}
                />
                )}
            />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label
              htmlFor="roomTypeId"
              className="block text-sm font-medium leading-6 mt-6 text-gray-900"
            >
              Room Type
            </label>
            <div className="mt-2">
            <Controller
                control={control}
                rules={{ required: true }}
                name={"roomTypeId"}
                render={({ field }) => {
                    return (
                        <Select  
                        options={roomTypesOptions} 
                        {...field}
                        onChange = {(val) => field.onChange(val.value)}
                        value = { field.value ? roomTypesOptions.find((c) => c.value == field.value) : null}
                        
                        />
                    )
                }}
            />
            </div>
          </div>

          <div className="flex-1 max-w-sm">
            <label htmlFor="comment" className="block text-sm font-medium text-gray-700">
                Room Number
            </label>
            <div className="mt-1">
                <input
                {...register("roomNumber")}
                type="text"
                id="comment"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={''}
                />
            </div>
          </div>
          { error && <div className="rounded-md bg-red-50 p-2 mt-4">
                        <div className="flex ">
                          <div className="flex-shrink-0">
                            <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                            <div className="mt-2 text-sm text-red-700">
                              <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                            </div>
                          </div>
                        </div>
                      </div>}
        </div>
      </div>

      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{props.closeModal && props.closeModal()}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




