
import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import Select from "react-select";
import { getData, patchData, postData } from "../../utils/apiUtil";

import { Datepicker } from 'flowbite-react';
import { removeEmptyFields } from "../../utils/helpers";
import { ExclamationIcon } from '@heroicons/react/outline'
import { useApi } from "../ApiProvider";
type SubscriptionFormData = {
  // Define the type for your form data
  subscriptionStartDate: string;
  subscriptionType: string;
  subscriptionPeriod: string;
  subscriptionPaymentDetails: {
    paymentMode: string|undefined;
    paymentAmount: string|undefined;
    paymentDate: string|undefined;
    paymentStatus: string|undefined;
  } 
// ...
};

type SubscriptionFormProps = {
    isEdit: boolean;
    branchId: number;
    orgId: number;
    subscription: SubscriptionFormData;
    closeModal?: () => void;
    selectedBranch: any;
    setSelectedBranch?: (branch: any) => void;
}
type ResponseErrors = {
    [field: string]: string[];
};

const subscriptionTypeOptions = [
    { value: 'FREE_TRIAL', label: 'FREE_TRIAL' },
    { value: 'PAID', label: 'PAID' },
]

const subscriptionPeriodOptions = [
    { value: '1', label: '1' },
    { value: '3', label: '3' },
    { value: '6', label: '6' },
    { value: '9', label: '9' },
    { value: '12', label: '12' },
  ]
  
//   const subscriptionStatusOptions = [
//     { value: 'ACTIVE', label: 'ACTIVE' },
//     { value: 'EXPIRED', label: 'EXPIRED' },
//   ]
  
  const paymentModeOptions = [
    { value: 'CASH', label: 'CASH' },
    { value: 'UPI', label: 'UPI' },
    { value: 'NETBANKING', label: 'NETBANKING' },
  ]
  
  const paymentStatusOptions = [
    { value: 'PENDING', label: 'PENDING' },
    { value: 'SUCCESSFUL', label: 'SUCCESSFUL' }
  ]

export function SubscriptionForm(props: SubscriptionFormProps) {
    console.log(" Subscription form props : ", props);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<SubscriptionFormData>({
    defaultValues: props.isEdit ? props.subscription : {}
  });
  const [error, setError] = useState("");
  const api = useApi();
  const [responseErrors, setResponseErrors] = useState<ResponseErrors>({});
  const onSubscriptionSubmit: SubmitHandler<SubscriptionFormData> = async (data: SubscriptionFormData) => {
    console.log("Subscription form submitted : ", data);

    if (data?.subscriptionPaymentDetails?.paymentDate && data.subscriptionPaymentDetails.paymentDate.indexOf("/") > -1) {
        const parts = data.subscriptionPaymentDetails.paymentDate.split("/")
        const convertedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        data.subscriptionPaymentDetails.paymentDate = convertedDate  
    }
    if (data?.subscriptionStartDate &&  data.subscriptionStartDate.indexOf("/") > -1) {
        const parts = data.subscriptionStartDate.split("/")
        const convertedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        data.subscriptionStartDate = convertedDate  
      }

    if(props.isEdit) {
        const requestBody = {
            subscriptionPeriodInMonths: data.subscriptionPeriod,
            subscriptionStartDate: data.subscriptionStartDate,
            subscriptionPaymentDetails:{
                subscriptionPaymentMode: data.subscriptionPaymentDetails?.paymentMode,
                subscriptionPaymentAmount: data.subscriptionPaymentDetails?.paymentAmount,
                subscriptionPaymentDate: data.subscriptionPaymentDetails?.paymentDate,
                subscriptionPaymentStatus: data.subscriptionPaymentDetails?.paymentStatus,
            }
        }
        const finalRequestBody = removeEmptyFields(requestBody);
      patchData(`${api.apiUrl}/subscription/${props.subscription.subscriptionId}`, finalRequestBody)
            .then((data) => {
            const responseSubscription = data.subscriptions[0];
            const updatedSubscription = {
                subscriptionId: responseSubscription.subscriptionId,
                subscriptionStartDate: responseSubscription.subscriptionStartDate,
                subscriptionType: responseSubscription.subscriptionType,
                subscriptionPeriod: responseSubscription.subscriptionPeriodInMonths,
                subscriptionStatus: responseSubscription.subscriptionStatus,
                subscriptionPaymentDetails: {
                    paymentMode: responseSubscription.subscriptionPaymentDetails?.subscriptionPaymentMode,
                    paymentAmount: responseSubscription.subscriptionPaymentDetails?.subscriptionPaymentAmount,
                    paymentDate: responseSubscription.subscriptionPaymentDetails?.subscriptionPaymentDate,
                    paymentStatus: responseSubscription.subscriptionPaymentDetails?.subscriptionPaymentStatus,
                }
            }
            props.setSelectedBranch && props.setSelectedBranch({...props.selectedBranch, subscriptionDetails: updatedSubscription});
            props.closeModal && props.closeModal();
            })
            .catch(error=> {
              setError(error)
            })
    } else {
        // const requestBody = {
        //     ...data,
        //     branchId: props.branchId,
        //     orgId: props.orgId,
        // }
      // postData(`${api.apiUrl}/subscription`, requestBody)
        // .then((data) => {
        //   props.closeModal && props.closeModal();
        // })
    }
    // postData(`${api.apiUrl}/floor/add`, data)
    // .then((data) => {
    //   props.closeModal && props.closeModal();
    // })
  };
  const isPaidSubscription = watch('subscriptionType') === "PAID" ? true : false;
  const isSubcriptionPaymentSuccessful = watch('subscriptionPaymentDetails.paymentStatus') === "SUCCESSFUL" ? true : false;
  const validateSubscriptionPaymentAmount = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment amount is required when subscription is paid';
    }
    return true;
  }
  const validateSubscriptionPaymentDate = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment date is required when subscription is paid';
    }
    return true;
  }
  const validateSubscriptionPaymentMode = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment mode is required when subscription is paid';
    }
    return true;
  }
const validateSubscriptionPaymentStatus = (value: string) => {
    if (isPaidSubscription && !value) {
      return 'Subscription payment status is required when subscription is paid';
    }
    return true;
  }
  const handleSubscriptionTypeChange = (event) => {
    if (event.target.value === "FREE_TRIAL") {
      setValue("subscriptionPaymentDetails", {})
      setValue("subscriptionPaymentDetails.paymentStatus", "")
      setValue("subscriptionPaymentDetails.paymentDate", "")
      setValue("subscriptionPaymentDetails.paymentMode", "")
      setValue("subscriptionPaymentDetails.paymentAmount", "")
    } else if(event.target.value === "PAID") {
      clearErrors(["subscriptionPaymentDetails.paymentMode", 
      "subscriptionPaymentDetails.paymentAmount", 
      "subscriptionPaymentDetails.paymentDate",
      "subscriptionPaymentDetails.paymentStatus"])

    }
  }
  const handleSubscriptionPaymentStatusChange = (event) => {
    setValue("subscriptionPaymentDetails.paymentMode", "")
    setValue("subscriptionPaymentDetails.paymentAmount", "")
    if (event.target.value === "SUCCESSFUL") {
      setValue("subscriptionPaymentDetails.paymentDate", formattedTodayDate)
    } else {
      setValue("subscriptionPaymentDetails.paymentDate", "")
      clearErrors(["subscriptionPaymentDetails.paymentDate", "subscriptionPaymentDetails.paymentMode", "subscriptionPaymentDetails.paymentAmount"])
    }
  }
  const today = new Date();
  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedTodayDate = today.toLocaleDateString('en-GB', dateOptions);

  return (
    <form className="w-full flex justify-content flex-col" onSubmit={handleSubmit(onSubscriptionSubmit)}>
      <div className="border-b border-gray-900/10 pb-12">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Subscription Information
        </h2>
        <div className="border-t border-gray-900/10 pb-12 mt-10">
              <h2 className="text-base font-semibold leading-7 mt-3 text-gray-900">Subscription Details</h2>
              <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">


              <div className="sm:col-span-3">
                <label
                htmlFor="room-sharing-type"
                className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Subscription Start Date
                </label>
                <div >
                <Controller
                      control={control}
                      defaultValue = {formattedTodayDate}
                      name={"subscriptionStartDate"}
                      
                      render={({ field }) => {
                        //field.value = field.value || new Date().toLocaleDateString();
                        return (
                          <Datepicker 
                          value={field.value}
                          onSelectedDateChanged={(date) => field.onChange(date.toLocaleDateString())}
                      />
                        )
                      }}
                  />
                </div>
                { responseErrors?.subscriptionStartDate?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                { errors?.subscriptionStartDate && <p className="text-sm text-red-500">{errors?.subscriptionStartDate?.message}</p>}
              </div>






                 <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                    Subscription Type
                  </label>
                  <select
                    disabled={props.isEdit}
                    {...register("subscriptionType", {
                      onChange: (event) => {
                        handleSubscriptionTypeChange(event);
                      }
                    })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {subscriptionTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors?.subscriptionType?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors?.subscriptionType && <p className="text-sm text-red-500">{errors?.subscriptionType?.message}</p>}
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Period In Months
                  </label>
                  <select
                    {...register("subscriptionPeriod")}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {subscriptionPeriodOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionPeriod?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors.subscriptionPeriod && <p className="text-sm text-red-500">{errors.subscriptionPeriod.message}</p>}
                </div>


                {/* <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Status
                  </label>
                  <select
                    disabled={true}
                    {...register("subscriptionStatus")}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {subscriptionStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionStatus?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors.subscriptionStatus && <p className="text-sm text-red-500">{errors.subscriptionStatus.message}</p>}
                </div> */}

                {isPaidSubscription &&<div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Payment Status
                  </label>
                  <select
                    {...register("subscriptionPaymentDetails.paymentStatus", {
                      validate: validateSubscriptionPaymentStatus,
                      onChange: (event) => {
                        handleSubscriptionPaymentStatusChange(event);
                      }
                    })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option selected value="" disabled>Select an option</option> 
                    {paymentStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionPaymentDetails?.paymentStatus?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors.subscriptionPaymentDetails?.paymentStatus && <p className="text-sm text-red-500">{errors.subscriptionPaymentDetails?.paymentStatus.message}</p>}
                </div>}

                {isPaidSubscription && isSubcriptionPaymentSuccessful && <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                    Subscription Payment Amount
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      {...register("subscriptionPaymentDetails.paymentAmount",{
                        validate: validateSubscriptionPaymentAmount,
                      })}
                      id="rate-per-unit"
                      autoComplete="rate-per-unit"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  { responseErrors.subscriptionPaymentDetails?.paymentAmount?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors.subscriptionPaymentDetails?.paymentAmount && <p className="text-sm text-red-500">{errors.subscriptionPaymentDetails?.paymentAmount.message}</p>}
                </div>}

                {isPaidSubscription && isSubcriptionPaymentSuccessful && <div className="sm:col-span-3">
                  <label htmlFor="notice-period" className="block text-sm font-medium text-gray-700">
                  Subscription Payment Mode
                  </label>
                  <select
                    {...register("subscriptionPaymentDetails.paymentMode", {
                      validate: validateSubscriptionPaymentMode
                    })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                     <option selected value="" disabled>Select an option</option>
                    {paymentModeOptions.map((option, index) => (
                      <option  key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  { responseErrors.subscriptionPaymentDetails?.paymentMode?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                  { errors.subscriptionPaymentDetails?.paymentMode && <p className="text-sm text-red-500">{errors.subscriptionPaymentDetails?.paymentMode.message}</p>}
                </div>}

                {isPaidSubscription && isSubcriptionPaymentSuccessful &&<div className="sm:col-span-3">
                <label
                htmlFor="room-sharing-type"
                className="block text-sm font-medium leading-6  text-gray-900"
                >
                  Payment Date
                </label>
                <div >
                <Controller
                      control={control}
                      defaultValue = {formattedTodayDate}
                      name={"subscriptionPaymentDetails.paymentDate"}
                      render={({ field }) => {
                        //field.value = field.value || new Date().toLocaleDateString();
                        return (
                          <Datepicker 
                          value={field.value}
                          onSelectedDateChanged={(date) => field.onChange(date.toLocaleDateString())}
                      />
                        )
                      }}
                  />
                </div>
                { responseErrors.subscriptionPaymentDetails?.paymentDate?.map(error =>  <p className="text-sm text-red-500">{error}</p> )}
                { errors.subscriptionPaymentDetails?.paymentDate && <p className="text-sm text-red-500">{errors.subscriptionPaymentDetails?.paymentDate.message}</p>}
                </div>}
              </div>

              { error && 
            <div className="rounded-md bg-red-50 p-2 mt-4">
              <div className="flex ">
                <div className="flex-shrink-0">
                  <ExclamationIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Errors : </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p className='whitespace-pre-line'> {error.toString()?.slice(7)} </p>
                  </div>
                </div>
              </div>
            </div>}

            </div> 


      </div>

        
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          className="text-sm font-semibold leading-6 text-gray-900"
          onClick={()=>{}}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}




