import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { getData } from '../../utils/apiUtil'
import { useApi } from '../ApiProvider'


type SubscriptionHistoryProps = {
   branchId: number;
}


export default function SubscriptionHistory(props: SubscriptionHistoryProps) {
    console.log("Subscription History props : ", props)
    
    const [subscriptionHistory, setSubscriptionHistory] = useState([])
    const api = useApi();
    const getSubscriptionHistory = (branchId: number) => {
        getData(`${api.apiUrl}/subscription/${branchId}`)
            .then((data) => {
                console.log("Subscription history : ", data)
                setSubscriptionHistory(data.subscriptions)
            })
    }
    useEffect(() => {
        getSubscriptionHistory(props.branchId)
    }, [])

    return (
        <>
        <div className='flex flex-row justify-between '>
            <div> Sub Type</div>
            <div> Sub Status</div> 
            <div> Sub Period</div>
            <div> Sub Payment Status</div>
            <div> Sub Payment Mode</div>
            <div> Sub Payment Date</div>
        </div>
        <div className='mt-4'>
        {subscriptionHistory.map((subscription) => (
                <div className='flex flex-row justify-between bg-grey-500'>
                    <div >{subscription?.subscriptionType || '-'}</div>
                    <div >{subscription?.subscriptionStatus || '-'}</div>
                    <div >{subscription?.subscriptionPeriodInMonths || '-'}</div>
                    <div >{subscription?.subscriptionPaymentDetails?.subscriptionPaymentStatus || '-'}</div>
                    <div >{subscription?.subscriptionPaymentDetails?.subscriptionPaymentMode || '-'}</div>
                    <div >{subscription?.subscriptionPaymentDetails?.subscriptionPaymentDate || '-'}</div>
                </div>
            ))
        }
        </div>
        </>
      
        // <div className="mt-8 flex flex-col bg-red-500">
        //   <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        //     <div className="inline-block min-w-full py-2 align-middle">
        //       <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
        //         <table className="min-w-full divide-y divide-gray-300">
        //           <thead className="bg-green-50">
        //             <tr>
        //               {/* <th
        //                 scope="col"
        //                 className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
        //               >
        //                 Floor Number
        //               </th> */}
        //               <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                 Subscription Type
        //               </th>
        //               <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                 Subscription Period
        //               </th>
        //               <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                 Subscription Payment Status
        //               </th>
        //               <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                Subscription Payment Mode
        //               </th>
        //               <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
        //                Subscription Payment Date
        //               </th>
        //             </tr>
        //           </thead>
        //           <tbody className="divide-y divide-gray-200 bg-yellow-800 ">
        //             {subscriptionHistory.map((subscription) => (
        //               <tr key={subscription?.subscriptionId} className='flex flex-row justify-start'>
        //                 {/* <td 
        //                     className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
        //                 >
        //                   {floor.floorNumber}
        //                 </td> */}
        //                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{subscription?.subscriptionType || '-'}</td>
        //                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{subscription?.subscriptionPeriodInMonths || '-'}</td>
        //                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{subscription?.subscriptionPaymentDetails?.subscriptionPaymentStatus || '-'}</td>
        //                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{subscription?.subscriptionPaymentDetails?.subscriptionPaymentMode || '-'}</td>
        //                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-1/4">{subscription?.subscriptionPaymentDetails?.subscriptionPaymentDate || '-'}</td>
        //               </tr>
        //             ))}
        //           </tbody>
        //         </table>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      
    )
}
