import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { BranchDetails } from './BranchDetails';
import { BranchForm } from '../forms/BranchForm';
import { Modal } from '../Modal';
import { getData } from '../../utils/apiUtil';
import { get } from 'lodash';
import { useApi } from '../ApiProvider';

const branches = [
    {
        branchId: 1,
        branchName: 'Apna Home 1',    
        managerName: 'Ashish Belide', 
        managerContactNumber: '+918328205208',
        pgCategory: 'Co-living',
        NoticePeriod: '1 month',
        LockinPeriod: '6 months',
        services: [
            {
                id: 1,
                name: 'Wifi'
            },
            {
                id: 2,
                name: 'Laundry'
            },
            {
                id: 3,
                name: 'House Keeping'
            },
            {
                id: 4,
                name: 'Food'
            }
        ],
        mealIncludedInRent: 'Yes',
        monthlyRentCollectionStartDate:1,
        totalBedsAvailable: 10,
        visitorsAllowed: 'No',
        electricityPaymentType: 'prepaid'

    },
    {
        id: 2,
        branchName: 'Apna Home 2',
        managerName: 'Akhil Belide', 
        managerContactNumber: '+918328205208',
        pgCategory: 'Boys',
        NoticePeriod: '2 month',
        LockinPeriod: '3 months',
        services: [
            {
                id: 1,
                name: 'Wifi'
            },
            {
                id: 2,
                name: 'Laundry'
            }
        ],
        mealIncludedInRent: 'Yes',
        monthlyRentCollectionStartDate:15,
        totalBedsAvailable: 15,
        visitorsAllowed: 'No',
        electricityPaymentType: 'prepaid'
    }


]

export  function OrgDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const org  = location.state.org;
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const api = useApi()
  const handleBranchClick = (branch) => {
    // get branch from branches where id equal branchId
    setSelectedBranch(branch)
    //setSelectedBranch(branch);
  }

  const handleAddBranchClick = () => {
    setShowModal(true)
  }


useEffect(() => {
  getData(`${api.apiUrl}/branches?orgId=${org.id}`)
  .then((data) => {
      console.log("Branch data : ", data.branches)
      setBranches(data.branches);
  })
}, []);


  return  (
    <div className='flex flex-row'>
    {showModal && 
        (
            <Modal showModal={showModal} onClose={()=>{setShowModal(false)}} >
               <BranchForm  
               isEdit={false}
               branch={{}}
               closeModal={()=>{
                setShowModal(false)
                getData(`${apiUrl}/branches?orgId=${org.id}`)
                .then((data) => {
                    console.log("Branch data: ", data.branches)
                    setBranches(data.branches);
                })
              }} 
               orgId={org.id} />
            </Modal>
        )}

    <div className="flex grow items-start">
      <div className="mt-6 border-t border-gray-100 w-full">

        <h2 className="text-lg font-medium leading-6 text-gray-900 mb-4">{org.brandName}</h2>
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Owner</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{org.ownerName}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Contact Number</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{org.phoneNumber}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Branch Names</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {branches && branches.map((branch) => (
                <div key={branch.branchId} onClick={() => handleBranchClick(branch)} className={`cursor-pointer hover:text-blue-500 ${selectedBranch?.id === branch.id ? 'text-blue-500 font-medium' : ''}`}>{branch.branchName}</div>
              ))}
              <div>
                <button 
                onClick = {() => handleAddBranchClick()}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Add Branch</button>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    {
        selectedBranch && 
        <BranchDetails 
          branches={branches}
          setBranches={setBranches}
          setSelectedBranch={setSelectedBranch} 
          selectedBranch={selectedBranch} 
          />}
    </div>
  )
}