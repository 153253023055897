import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCAfa1YB-fWRZ_o6otwK2d02zSA_ga7mRE",
  authDomain: "tenantz-management-service.firebaseapp.com",
  projectId: "tenantz-management-service",
  storageBucket: "tenantz-management-service.appspot.com",
  messagingSenderId: "117981609545",
  appId: "1:117981609545:web:790aa6aee85b0c760e4998",
  measurementId: "G-9KFQ707PSN",
};
initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BLiUX_01gS5Kv9wcRPYUQWnncku6lc0L2V4i4m7La5Sgnyx_fk4p_-H3VcATFWR3Www2AuSH_3k7ra9culwno64",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });